import PouchDBAuthentication from 'pouchdb-authentication';
import PouchDBFind from 'pouchdb-find';
import PouchDB from 'pouchdb';

import { COUCH_URL } from "../config/database"
import { dbname } from "../config/customer"

export function testConnection(connectionResultHandler) {
    return new Promise((resolve, reject) => {
        fetch(new Request(COUCH_URL + '_up/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            mode: 'cors',
            cache: 'default',
        })).then((result) => {
            if (result.ok) {
                resolve('authorized');
            } else if (result.status === 401) {
                resolve('unauthorized');
            } else {
                resolve('error');
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


export function localDatabaseHasBeenSetup(db) {
    return new Promise((resolve, reject) => {
        db.get(`set${dbname}up`).then(function (doc) {
            if(doc && doc.hasOwnProperty('set') && doc.set === true) {
                resolve(true)
            } else {
                resolve(false)
            }
        }).catch(err => {
            resolve(false)
        });
    })
}

export function loginToRemoteDb(username, password) {
    return new Promise((resolve, reject) => {
        var db = new PouchDB(COUCH_URL + dbname, { skip_setup: true });

        db.login(username, password, {
            ajax: {
                headers: {
                    Authorization: 'Basic ' + window.btoa(username + ':' + password)
                }
            }
        }).then(() => {
            resolve(db);
        }).catch((error) => {
            reject(error);
        });
    });
}


export function initDatabase() {
    PouchDB.plugin(PouchDBFind);
    PouchDB.plugin(PouchDBAuthentication);

    return new PouchDB(dbname);
}

export function syncDatabases(localDb, remoteDb) {
    return new Promise((resolve, reject) => {
        if (remoteDb === undefined) {
            remoteDb = new PouchDB(COUCH_URL + dbname, { skip_setup: true });
        }

        // synching first then activating live sync
        localDb.sync(remoteDb).on('complete', function () {
            localDb.sync(remoteDb, {
                live: true,
                retry: true,
                timeout: false,
                heartbeat: false
            }).catch((error) => {
                reject(error);
            });
            resolve(localDb);
        }).on('error', (err) => {
            reject(err);
        });
    });
}