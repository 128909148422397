import React from 'react'

import { Typography } from '@mui/material';
import { red } from '@mui/material/colors';

export default function ErrorLabel({message}) {    
    return (
        <Typography sx={{
            color: red[500],
            textAlign: "right",
            margin: 0
        }}>
            {message}
        </Typography>
    );
}