import React from 'react';
import { Ellipse as KonvaEllipse, Transformer } from 'react-konva';

export default function Ellipse({
    id,
    shapeProps,
    otherProps,
    stageDimensions,
    isSelected = false,
    onSelect = null,
    onChange = null
}) {
    const shapeRef = React.useRef();
    const transformerRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            transformerRef.current.nodes([shapeRef.current]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [shapeRef, isSelected]);

    const handleSelectShape = () => {
        onSelect(id);
    };
    
    const onDragEnd = (event) => {
        let x = event.target.x();
        let y = event.target.y();
        
        if      (x < 50)                            { x = 50; }
        else if (x > stageDimensions.width - 50)    { x = stageDimensions.width - 50; }

        if      (y < 50)                            { y = 25; }
        else if (y > stageDimensions.height - 50)   { y = stageDimensions.height - 50;  }

        onChange(id, {
            ...shapeProps,
            x: x,
            y: y,
        });
    };

    const onTransformEnd = (e) => {
        const node = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);
        
        onChange(id, {
            ...shapeProps,
            x: Math.round(node.x()),
            y: Math.round(node.y()),
            //  setting minimal value:
            width: Math.max(5, Math.round(node.width() * scaleX)),
            height: Math.max(5, Math.round(node.height() * scaleY)),
            rotation: Math.round(node.rotation()*100)/100
        });
    };
    
    const shapeEvents = { };

    if(onSelect) {
        shapeEvents.onClick             = handleSelectShape;
        shapeEvents.onTap               = handleSelectShape;
        shapeEvents.draggable           = false;
    } else if(onChange) {
        if(isSelected) {
            shapeEvents.draggable       = true;
            shapeEvents.onDragEnd       = onDragEnd;
            shapeEvents.onTransformEnd  = onTransformEnd;
        } else {
            shapeEvents.draggable       = false;
        }
    }

    return (
        <>
        <KonvaEllipse
            ref={shapeRef}
            {...shapeProps}
            {...otherProps}
            {...shapeEvents}
        />
        {isSelected && (
            <Transformer
                ref={transformerRef}
                boundBoxFunc={(oldBox, newBox) => {
                    // limit resize
                    if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                    }
                    return newBox;
                }}
                keepRatio={false}
                enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                anchorStroke="#666666"
                anchorFill='#dddddd'
                borderStroke="#b2b2b2"
                anchorSize={20}
                anchorCornerRadius={10}
                borderStrokeWidth={2}
                borderDash={[18, 5, 2, 8]}
                rotateAnchorOffset={38}
            />
        )}
        </>
    );
}