import PetsIcon from '@mui/icons-material/Pets';

export const ANIMAL_ICON = <PetsIcon />;

export const ANIMAL_DOC_PREFIX = 'animals/';

export const ANIMALS_TYPES = {
    'cat': {
        name: 'Chat',
        icon: '🐱'
    },
    'dog': {
        name: 'Chien',
        icon: '🐶'
    },
    'horse': {
        name: 'Cheval',
        icon: '🐴'
    },
    'pony': {
        name: 'Poney',
        icon: '🐴'
    },
    'cow': {
        name: 'Vache',
        icon: '🐄'
    },
    'sheep': {
        name: 'Mouton',
        icon: '🐑'
    },
    'goat': {
        name: 'Chèvre',
        icon: '🐐'
    },
    'nac': {
        name: 'NAC',
        icon: '🐰'
    },
    'bird': {
        name: 'Oiseau',
        icon: '🐓'
    },
    'other': {
        name: 'Autre',
        icon: '🐾'
    }
};

export const ANIMALS_SEXES = {
    'undefined': 'Indéfini',
    'female': 'Femelle',
    'male': 'Mâle',
};

export const CHECKUP_REMINDER = {
    'none': 'Aucun',
    'be-annual': 'Bi-annuel',
    'annual': 'Annuel',
};

export const STERILIZED_OPTIONS = {
    'undefined': 'Non renseigné',
    'yes': 'Oui',
    'no': 'Non',
};