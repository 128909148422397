import React, { useState, useEffect, useMemo, useCallback } from 'react'

import { useParams, useNavigate, useLocation, Outlet } from 'react-router-dom'       //useLocation

import { usePouch } from 'use-pouchdb'

import { ANIMAL_DOC_PREFIX } from '../config/animals';
import { SESSION_DOC_PREFIX } from '../config/sessions';

import ClientContext from '../contexts/ClientContext';

import { fetchClient as _fetchClient, updateClient, deleteClient } from '../models/clients'
import { createAnimal, deleteAnimal, updateAnimal } from '../models/animals';
import { createSession, deleteSession, sendSessionReport, updateSession } from '../models/sessions';

import { animalPath, clientPath, clientsListPath, sessionPath } from '../services/routesPaths';

import Screen from './UI/Screen';

import { addAnimalLink, addClientLink, addSessionLink, withClientsList } from '../services/breadcrumbs';

import * as config from '../config/customer.js'

export default function ClientProvider() {
    //const location = useLocation();
    const params = useParams();
    const location = useLocation();

    const navigate = useNavigate();
    const db = usePouch();

    // If action == create client (ie no clients/:clientId in the route), we don't init Client
    let initClient = params.hasOwnProperty("clientId");
    
    const [client, setClient] = useState(null);

    const pathname = location.pathname;

    const providerValue = useMemo(() => {
        let breadcrumbs = withClientsList();
        
        const currentClientId = client?._id;
        const currentAnimalId = (params.animalId ? ANIMAL_DOC_PREFIX+params.animalId : null);
        const currentSessionId = (params.sessionId ? SESSION_DOC_PREFIX+params.sessionId : null);
        
        let currentAnimal = null;
        let currentSession = null;

        if(initClient && client) {
            //if(params.hasOwnProperty("animalId") || pathname.endsWith('animals/create')) {
            if(currentAnimalId || pathname.endsWith('animals/create')) {
                if(currentAnimalId) {
                    for(const i in client.animals) {
                        if(client.animals[i]._id === currentAnimalId) {
                            currentAnimal = client.animals[i];
                        }
                    }
                }

                breadcrumbs = addClientLink(breadcrumbs, client);

                //if(params.hasOwnProperty("sessionId") || pathname.endsWith('sessions/create')) {
                if(currentSessionId || pathname.endsWith('sessions/create')) {
                    if(currentSessionId) {
                        for(const i in currentAnimal.sessions) {
                            if(currentAnimal.sessions[i]._id === currentSessionId) {
                                currentSession = currentAnimal.sessions[i];
                            }
                        }
                    }

                    breadcrumbs = addAnimalLink(
                        breadcrumbs,
                        client._id,
                        currentAnimal
                    );
                    breadcrumbs = addSessionLink(
                        breadcrumbs,
                        (pathname.endsWith('sessions/create')
                            ? "Nouvelle séance"
                            : currentSession.number
                        )
                    );
                } else {
                    breadcrumbs = addAnimalLink(
                        breadcrumbs,
                        client._id,
                        (pathname.endsWith('animals/create')
                            ? "Nouvel animal"
                            : currentAnimal.name
                        )
                    );
                }
            } else {
                breadcrumbs = addClientLink(
                    breadcrumbs,
                    client.name
                );
            }
        }
        
        return {
            header: {
                title: config.title,
                breadcrumbs: breadcrumbs
            },
            client: client,
            getCurrentAnimal: () => {
                return currentAnimal;
            },
            getCurrentSession: () => {
                return currentSession;
            },
            updateClientInfos: clientInfos => {
                // TODO : éviter rerender ?
                const newClient = {...client, ...clientInfos};

                setClient(null);

                updateClient(db, newClient).then(newRev => {
                    //newClientInfos._rev = newRev;
                    //setClient({...client, infos: newClientInfos});
                    navigate(clientPath(currentClientId)/*, {
                        state: {
                            client: {...client, infos: newClientInfos }
                        }
                    }*/)
                });
            },
            deleteClient: () => {
                setClient(null);

                deleteClient(db, client).then(navigate(clientsListPath())); //, { replace: true }));
            },
            createAnimal: animal => {
                setClient(null);
                
                createAnimal(db, client, animal).then(animalId => {
                    navigate(animalPath(currentClientId, animalId))
                });
            },
            updateAnimal: animalInfos => {
                setClient(null);
                
                updateAnimal(db, client, currentAnimalId, animalInfos).then(response => {
                    navigate(animalPath(currentClientId, currentAnimalId))
                });
            },
            deleteAnimal: animal => {
                setClient(null);

                deleteAnimal(db, client, animal._id).then(navigate(clientPath(currentClientId)));
            },
            createSession: session => {
                setClient(null);
                
                createSession(db, client, currentAnimalId, session).then(sessionId => {
                    navigate(sessionPath(currentClientId, currentAnimalId, sessionId))
                });
            },
            updateSession: session => {
                setClient(null);
                
                updateSession(db, client, currentAnimalId, currentSessionId, session).then(newRev => {
                    navigate(sessionPath(currentClientId, currentAnimalId, currentSessionId))
                });
            },
            deleteSession: session => {
                setClient(null);

                deleteSession(db, client, currentAnimalId, session._id).then(
                    navigate(animalPath(currentClientId, currentAnimalId))
                );
            },
            sendReport: session => {
                sendSessionReport(db, client, currentAnimalId, session._id);
            }
        };
    }, [client, db, navigate, params, initClient, pathname]);
    
    const fetchClient = useCallback(() => {
        _fetchClient(db, params.clientId).then(client => setClient(client));
    }, [db, params]);

    useEffect(() => {
        let dbChangesListener = null;

        if(initClient) {
            if(!client) {
                fetchClient();
            } else {
                dbChangesListener = db.changes({
                    since: 'now',
                    live: true,
                    include_docs: true,
                }).on('change', (change) => {
                    //  If update have been made on current client, we reload it
                    if(change.id.startsWith('clients/') &&  change.id === client._id) {
                        fetchClient();
                    }
                });

            }
        }
        
        return () => {
            if(dbChangesListener) {
                dbChangesListener.cancel();
            }
        };
    }, [client, initClient, fetchClient, db, pathname, providerValue, params]);

    
    return (
        <ClientContext.Provider value={providerValue}>
            {(client || !initClient) ? <Outlet /> : <Screen header={providerValue.header} loading />}
        </ClientContext.Provider>
    );
}