import { ANIMALS_TYPES, ANIMAL_DOC_PREFIX } from "../config/animals"
import { updateClient } from "./clients";


export function getAnimalTypeDisplayName(type) {
    return ANIMALS_TYPES[type].icon + ' ' + ANIMALS_TYPES[type].name;
}

export function createAnimal(db, client, animal) {
    return new Promise((resolve, reject) => {
        const id = ANIMAL_DOC_PREFIX + Date.now();

        if(!animal.hasOwnProperty('checkupReminder')) {
            animal.checkupReminder = null;
        }

        client.animals.push({
            _id: id,
            created: new Date().toISOString(),
            ...animal,
            lastSessionDate: null,
            newsRequestStatus: null,
            checkupReminderStatus: null,
            sessions: []
        });

        updateClient(db, client).then(response => {
            resolve(id);
            //resolve(client);
        }).catch(function (err) {
            console.log(err);
            reject(err);
        });
    });
}

export function updateAnimal(db, client, animalId, animalInfos) {
    return new Promise((resolve, reject) => {
        for(const i in client.animals) {
            if(animalId === client.animals[i]._id) {
                client.animals[i] = {
                    ...client.animals[i],
                    ...animalInfos
                };
                break;
            }
        }
        updateClient(db, client).then(response => {
            resolve(true);
            //resolve(client);
        }).catch(function (err) {
            console.log(err);
            reject(err);
        });
    });
}

export function deleteAnimal(db, client, animalId) {
    return new Promise((resolve, reject) => {
        client.updateTarget = {
            action: "animal_delete",
            animalId: animalId
        };

        for(const i in client.animals) {
            if(animalId === client.animals[i]._id) {
                delete client.animals.splice(i, 1);
                break;
            }
        }
        updateClient(db, client).then(response => {
            resolve(true);
            //resolve(client);
        }).catch(function (err) {
            console.log(err);
            reject(err);
        });
    });
}

export function getAnimalAge(birthYear) {
    let age = null;
    
    if(birthYear) {
        const today = new Date(Date.now());
        age = today.getFullYear() - birthYear;

        if(age < 0) {
            age = 0;
        }
    }

    return age;
}

export function getAnimalAgeStr(birthYear) {
    let age = getAnimalAge(birthYear);
    
    if(age === null) {
        return "";
    }
    
    if(age < 1) {
        return "< 1 an";
    } else if(age < 2) {
        return "1 an";
    } else {
        return age.toString() + " ans";
    }
}

export function getAnimalDeathAge(birthYear, deathYear) {
    let deathAge = null;

    if(birthYear && deathYear) {
        deathAge = deathYear - birthYear;

        if(deathAge < 0) {
            deathAge = 0;
        }
    }

    return deathAge;
}

export function getAnimalDeathAgeStr(birthYear, deathYear) {
    let deathAge = getAnimalDeathAge(birthYear, deathYear);
    
    if(deathAge === null) {
        return "";
    }
    
    if(deathAge < 1) {
        return deathYear.toString() + " (avant 1 an)";
    } else if(deathAge < 2) {
        return deathYear.toString() + " ( à 1 an)";
    } else {
        return deathYear.toString() + " (à " + deathAge + " ans)";
    }
}