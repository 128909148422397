import React, { useContext } from 'react';

import ClientContext from '../../../contexts/ClientContext';

import Screen from '../../UI/Screen';
import SessionForm from '../Form';

import { animalPath } from '../../../services/routesPaths';

export default function CreateSession() {
    const {
        client,
        createSession,
        getCurrentAnimal,
        header
    } = useContext(ClientContext);

    const animal = getCurrentAnimal();

    return (
        <Screen header={header}>
            <SessionForm
                onSubmit={createSession}
                cancelButtonProps={{
                    to: animalPath(client._id,  animal._id)
                }}
                animal={animal}
            />
        </Screen>
    );
}