export function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, '');

    // Make the string lowercase
    str = str.toLowerCase();

    // Remove accents, swap ñ for n, etc
    var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '') 
    // Collapse whitespace and replace by -
    .replace(/\s+/g, '-') 
    // Collapse dashes
    .replace(/-+/g, '-'); 

    return str;
}

export function formatName(name, forceCapitalize) {
    name = name.trim();

    if(forceCapitalize) {
        name = name.toLowerCase().replaceAll(/(['\- ])([a-ú])/ig, (match, p1, p2, offset, string, groups) => {
            return p1+p2.toUpperCase();
        });

        name = name.replaceAll(/([- ](Sur[- ]|Le[- ]|La[- ]|Les[- ]|L'|D'|Du[- ]|De[- ]|Des[- ]))/g, (match, p1, offset, string, groups) => {
            return p1.toLowerCase();
        });
        // 2 times for cases like "L'Isle-Sur-La-Sorgue" : 2 catching groups following each other
        name = name.replaceAll(/([- ](Sur[- ]|Le[- ]|La[- ]|Les[- ]|L'|D'|Du[- ]|De[- ]|Des[- ]))/g, (match, p1, offset, string, groups) => {
            return p1.toLowerCase();
        });
    } else {
        name = name.replaceAll(/([A-zÀ-ú])([A-Ú])/g, (match, p1, p2, offset, string, groups) => {
            return p1+p2.toLowerCase();
        });
    }
    
    return capitalize(name);
}

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}