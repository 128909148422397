import React from 'react';
import { Line, CurvedLine, Arrow, CurvedArrow, Cross, Ellipse } from '.';

export default function Shape({
    id,
    type,
    shapeProps,
    otherProps = {},
    stageDimensions,
    isSelected = false,
    eventsHandlers = null
}) {
    if(!isSelected) {
        otherProps.preventDefault = false;
    }

    switch(type) {
        case 'line':
            return <Line
                        id={id}
                        shapeProps={shapeProps}
                        otherProps={otherProps}
                        stageDimensions={stageDimensions}
                        isSelected={isSelected}
                        {...eventsHandlers}
                    />;
        case 'curvedLine':
            return <CurvedLine
                        id={id}
                        shapeProps={shapeProps}
                        otherProps={otherProps}
                        stageDimensions={stageDimensions}
                        isSelected={isSelected}
                        {...eventsHandlers}
                    />;
        case 'arrow':
            return <Arrow
                        id={id}
                        shapeProps={shapeProps}
                        otherProps={otherProps}
                        stageDimensions={stageDimensions}
                        isSelected={isSelected}
                        {...eventsHandlers}
                    />;
        case 'curvedArrow':
            return <CurvedArrow
                        id={id}
                        shapeProps={shapeProps}
                        otherProps={otherProps}
                        stageDimensions={stageDimensions}
                        isSelected={isSelected}
                        {...eventsHandlers}
                    />;
        case 'cross':
            return <Cross
                        id={id}
                        shapeProps={shapeProps}
                        otherProps={otherProps}
                        stageDimensions={stageDimensions}
                        isSelected={isSelected}
                        {...eventsHandlers}
                    />;
        case 'ellipse':
            return <Ellipse
                        id={id}
                        shapeProps={shapeProps}
                        otherProps={otherProps}
                        stageDimensions={stageDimensions}
                        isSelected={isSelected}
                        {...eventsHandlers}
                    />;
        default:
            return '';
    }
}