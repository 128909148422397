import React, { useContext } from 'react';

import ClientContext from '../../../contexts/ClientContext';

import Screen from '../../UI/Screen';
import ClientForm from '../Form';
import { clientPath } from '../../../services/routesPaths';

export default function UpdateClient() {
    const { client, updateClientInfos, header } = useContext(ClientContext);
    
    return (
        <Screen header={header}>
            <ClientForm 
                values={client}
                onSubmit={updateClientInfos}
                cancelButtonProps={{
                    to: clientPath(client._id)
                }}
            />
        </Screen>
    );
}
/*, state: {client: client}*/