import React, { useState, useContext } from 'react';

import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom'

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import ClientContext from '../../../contexts/ClientContext';

import Screen from '../../UI/Screen';
import ConfirmDialog from '../../UI/ConfirmDialog';

import ClientInfos from './Infos';
import AnimalsList from '../../Animals/List';

import { createAnimalPath, updateClientPath } from '../../../services/routesPaths';

export default function ViewClient() {
    const { client, deleteClient, header } = useContext(ClientContext);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleDeleteClient = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmDeleteClient = () => {
        deleteClient();
        setConfirmDialogOpen(false);
    };

    const handleCancelDeleteClient = () => {
        setConfirmDialogOpen(false);
    };
    
    return (
        <Screen header={header}>
            <Card elevation={3}>
                <CardContent className="detailsCardContent">
                    <Typography className="detailsCardTitle" color="primary" gutterBottom>
                        Informations personnelles
                    </Typography>
                    <ClientInfos infos={client} />
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteClient}
                        color="secondary"
                    >
                        Supprimer
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        component={RouterLink}
                        to={updateClientPath(client._id)}
                    >
                        Modifier
                    </Button>
                </CardActions>   
            </Card>

            <Card elevation={2}>
                <CardContent>
                    <Typography className="detailsCardTitle" color="primary" gutterBottom>
                        Animaux
                    </Typography>
                    <AnimalsList animals={client?.animals} />
                </CardContent>
                <CardActions className="cardActionCenter">
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        color="primary"
                        component={RouterLink}
                        to={createAnimalPath(client._id)}
                    >
                        Ajouter
                    </Button>
                </CardActions>  
            </Card>

            <ConfirmDialog
                open={confirmDialogOpen}
                title="Suppression d'une fiche client"
                onCancel={handleCancelDeleteClient}
                onConfirm={handleConfirmDeleteClient}
            >
                Vous êtes sur le point de supprimer définitivement une fiche client, toutes les informations sur ses animaux et leurs comptes-rendus de séances seront aussi définitivement perdus. Souhaitez-vous continuer ?
            </ConfirmDialog>
        </Screen>
    );
}