import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';

export const CLIENT_DOC_PREFIX = 'clients/';

export const CLIENT_ICON = <PersonIcon />;
export const CLIENTS_LIST_ICON = <ListAltIcon/>;

export const CONTACT_MODES = {
    'none': 'Aucun',
    'email': 'Email',
    'phone': 'Téléphone (SMS)',
    'emailAndPhone': 'Email & SMS',
};

export const NB_CLIENTS_PER_FETCH = 30;