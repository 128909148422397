import React, { useState } from 'react';
import { Line as KonvaLine } from 'react-konva';
import Anchor from './Anchor';

export default function Line({
    id,
    shapeProps,
    otherProps,
    stageDimensions,
    isSelected = false,
    onSelect = null,
    onChange = null
}) {
    const shapeRef = React.useRef();

    const [linePoints, setLinePoints] = useState(shapeProps.points);
    
    const onAnchorDragMove = (target, x, y) => {
        const newLinePoints = [...linePoints];

        if      (x < 25)                            { x = 25; }
        else if (x > stageDimensions.width - 25)    { x = stageDimensions.width - 25; }

        if      (y < 25)                            { y = 25; }
        else if (y > stageDimensions.height - 25)   { y = stageDimensions.height - 25;  }

        if(target === 1) {
            newLinePoints[0] = x;
            newLinePoints[1] = y;
        } else if(target === 2) {
            newLinePoints[2] = x;
            newLinePoints[3] = y;
        }

        setLinePoints(newLinePoints);
    };

    const onAnchorDragEnd = (target, x, y) => {
        const newLinePoints = [...linePoints];

        if      (x < 25)                            { x = 25; }
        else if (x > stageDimensions.width - 25)    { x = stageDimensions.width - 25; }

        if      (y < 25)                            { y = 25; }
        else if (y > stageDimensions.height - 25)   { y = stageDimensions.height - 25;  }

        if(target === 1) {
            newLinePoints[0] = Math.round(x);
            newLinePoints[1] = Math.round(y);
        } else if(target === 2) {
            newLinePoints[2] = Math.round(x);
            newLinePoints[3] = Math.round(y);
        }

        onChange(id, {
            ...shapeProps,
            points: newLinePoints
        });
    };

    const handleSelectShape = () => {
        onSelect(id);
    };
    const onSelectShapeHandler = (onSelect ? handleSelectShape : undefined);

    return (
        <>
        <KonvaLine
            onClick={onSelectShapeHandler}
            onTap={onSelectShapeHandler}
            ref={shapeRef}
            {...shapeProps}
            {...otherProps}
            points={linePoints}
        />
        {isSelected && (
            <>
            <Anchor
                number={1}
                x={linePoints[0]}
                y={linePoints[1]}
                onDragMove={onAnchorDragMove}
                onDragEnd={onAnchorDragEnd}
            />
            <Anchor
                number={2}
                x={linePoints[2]}
                y={linePoints[3]}
                onDragMove={onAnchorDragMove}
                onDragEnd={onAnchorDragEnd}
            />
            </>
        )}
        </>
    );
}