module.exports = {
    title: "Symbiose Animale",
    dbname: "symbiose",
    options: {
        sendReports: true,
        newsRequests: false,
        checkupReminders: false
    },
    search: {
        fields: {
            clients: [ "name", "email", "phone", "company" ],
            animals: [ "name" ]
        }
    },
    animals: {
        privateNotes: true,
        sterilized: true,
        veterinary: true
    },
    sessions: {
        numberFormat: "{clientName}_{animalName}_{date}_{reason}",
        numberFormatDateFormat: "yyyy-MM-dd",
        drawnEditor: {
            defaultShapeProps: {
                stroke: {
                    color: "#f9974e"
                }
            }
        }
    },
    theme: {
        palette: {
            primary: {
                main: "#EB9C5C",
                dark: "#D0894F",
                contrastText: "#fff"
            },
            secondary: {
                main: "#978670",
                dark: "#796D5F",
                contrastText: "#fff",
            },
            cancel: {
                main: "#978670",
                dark: "#796D5F",
                contrastText: "#fff",
            },
        },
    }
}