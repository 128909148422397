import React, { useEffect, useState, useReducer } from 'react';

import { Box } from '@mui/material';

import FullscreenDialog from '../../../UI/FullscreenDialog';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';

import { Stage, Layer, Rect } from 'react-konva';

import Toolbar from './EditorToolbar';

import Shape from '../Shapes/Shape';

import { DEFAULT_SHAPE_PROPS } from '../../../../config/sessions';


const getInsertedShapeProps = (type, x, y) => {
    switch(type) {
        case 'line':
        case 'arrow':
            if(x < 120) {
                if(y < 30) {
                    return { points: [30, 30, 230, 30] };
                } else {
                    return { points: [30, y, 230, y] };
                }
            } else if(y < 30) {
                return {points: [x-100, 30, x+100, 30]};
            } else {
                return {points: [x-100, y, x+100, y]};
            }
        case 'curvedLine':
        case 'curvedArrow':
            if(x < 120) {
                if(y < 30) {
                    return { points: [30, 30, 130, 30, 230, 30] };
                } else {
                    return { points: [30, y, 130, y, 230, y] };
                }
            } else if(y < 30) {
                return {points: [x-100, 30, x, 30, x+100, 30]};
            } else {
                return { points: [x-100, y, x, y, x+100, y] };
            }
        case 'cross':
        case 'ellipse':
            return {x: x, y: y};
        default:
    }
};

let newShapes = null;

const shapeReducer = (currentState, action) => {
    switch(action.type) {
        case 'ADD_SHAPE':
            return {
                ...currentState,
                status: 'ADD_SHAPE',
                shapeTypeToAddOrSelected: action.shapeType,
                selectedShapeId: null
            };
        case 'CANCEL_ADD_SHAPE':
            return {
                ...currentState,
                status: null,
                shapeTypeToAddOrSelected: null
            };
        case 'SELECT_SHAPE':
            return {
                ...currentState,
                status: 'SELECT_SHAPE',
                selectedShapeId: null
            };
        case 'CANCEL_SELECT_SHAPE':
            return {
                ...currentState,
                status: null
            };
        case 'SHAPE_SELECTED':
            //  Reordering shapes (zIndex):
            newShapes = {...currentState.shapes};
            const selectedShape = currentState.shapes[action.shapeId];
            
            delete newShapes[action.shapeId];

            return {
                ...currentState,
                status: 'SHAPE_SELECTED',
                shapes: {...newShapes, [action.shapeId]: selectedShape},
                selectedShapeId: action.shapeId,
                shapeTypeToAddOrSelected: currentState.shapes[action.shapeId].type
            };
        case 'UNSELECT_SHAPE':
            return {
                ...currentState,
                status: null,
                selectedShapeId: null,
                shapeTypeToAddOrSelected: null
            };
        case 'INSERT_SHAPE':
            newShapes = {...currentState.shapes};
            const shapeId = String(Date.now());
            newShapes[shapeId] = {
                type: currentState.shapeTypeToAddOrSelected,
                props: {
                    ...DEFAULT_SHAPE_PROPS[currentState.shapeTypeToAddOrSelected],
                    ...getInsertedShapeProps(
                        currentState.shapeTypeToAddOrSelected,
                        action.coordinates.x,
                        action.coordinates.y
                    )
                }
            };
            
            return {
                ...currentState,
                shapes: newShapes,
                status: 'SHAPE_SELECTED',
                selectedShapeId: shapeId
            };
        case 'SHAPE_UPDATED':
            newShapes = {...currentState.shapes};
            newShapes[action.shapeId].props = action.newProps;
            return {
                ...currentState,
                shapes: newShapes
            };
        case 'SHAPE_DELETED':
            newShapes = {...currentState.shapes};
            delete newShapes[currentState.selectedShapeId];

            return {
                ...currentState,
                shapes: newShapes,
                status: null,
                selectedShapeId: null,
                shapeTypeToAddOrSelected: null
            };
        case 'INIT':
            return {
                ...currentState,
                shapes: action.shapes
            };
        default:
            return currentState;
    }
}


export default function DrawnReportEditor({
    image,
    imageInfos,
    direction,
    dialogTitle,
    handleSaveShapes,
    handleClose,
    shapes = {}
}) {
    const [stageState, setStageState ] = useState({
        width: 0,
        height: 0,
        scale: 1
    });

    const [state, dispatch] = useReducer(shapeReducer, {
        status: null,
        selectedShapeId: null,
        shapeTypeToAddOrSelected: null,
        shapes: {}
    });

    useEffect(() => {
        dispatch({
            type: 'INIT',
            shapes: shapes
        });

        const updateStateStage = () => {
            const scale = (document.getElementById('stageWrapper-'+direction).offsetWidth-38) / imageInfos.width;
        
            setStageState({
                width: imageInfos.width * scale,
                height: imageInfos.height * scale,
                scale: scale
            });
        };
        
        window.addEventListener('resize', updateStateStage);

        // Giving some time to UI dialog to finish animation and load stage
        const initStage = setInterval(() => {
            if(document.getElementById('stageWrapper-'+direction) !== undefined) {
                updateStateStage();
                clearInterval(initStage);
            }
        }, 1);

        return () => {
            window.removeEventListener('resize', updateStateStage);
        }
    }, [shapes, direction, imageInfos]);


    const handleClickStage = (event) => {
        if (event.target?.attrs?.id === "backgroundImage") {
            if(state.status === 'ADD_SHAPE') {
                let x, y;
                if(event.evt.hasOwnProperty('offsetX')) {
                    x = event.evt.offsetX;
                    y = event.evt.offsetY;
                } else {
                    x = event.currentTarget.pointerPos.x;
                    y = event.currentTarget.pointerPos.y;
                }
                
                dispatch({
                    type: 'INSERT_SHAPE',
                    coordinates: {
                        x: x / event.currentTarget.attrs.scaleX,
                        y: y / event.currentTarget.attrs.scaleY
                    }
                });
            } /*else if(state.status !== 'SELECT_SHAPE') {
                dispatch({
                    type: 'UNSELECT'
                });
            };*/
        }
    };

    const onAddShape = (type) => {
        dispatch({
            type: 'ADD_SHAPE',
            shapeType: type
        });
    };
    
    /*  Triggered from toolbar :
     *      - SELECT_SHAPE
     *      - ADD_SHAPE
     *      - CANCEL_SELECT_SHAPE
     *      - CANCEL_ADD_SHAPE
     */
    const onStatusChange = (newStatus, params = null) => {
        dispatch({
            type: newStatus,
            ...params
        })
    };

    const onShapeSelect = (shapeId) => {
        dispatch({
            type: 'SHAPE_SELECTED',
            shapeId: shapeId
        });
    };

    const onShapeChange = (shapeId, newProps) => {
        dispatch({
            type: 'SHAPE_UPDATED',
            shapeId: shapeId,
            newProps: newProps
        });
    };

    const onSave = () => {
        handleSaveShapes(state.shapes, (Object.keys(state.shapes).length ? stageRef.current.toDataURL({
            mimeType: "image/jpeg",
            quality: 0.6,
            pixelRatio: 1/stageState.scale
        }) : null));
    };
    
    const stageRef = React.useRef(null);

    const stageDimensions = {
        width: imageInfos.width,
        height: imageInfos.height
    };

    let isSelected, eventsHandlers;

    return (
        <FullscreenDialog
            onClose={handleClose}
            title={dialogTitle}
            icon={<EditIcon />}
            leftButton={{
                icon: <ClearIcon />,
                ariaLabel: "Annuler",
                onClick: handleClose
            }}
            rightButton={{
                icon: <SaveIcon />,
                ariaLabel: "Enregistrer",
                onClick: onSave,
                disabled: (state.status === null ? undefined : true)
            }}
            containerClassName="drawn-report-editor"
        >
            <Box
                id={`editorWrapper-${direction}`}
                variant="text"
                sx={{
                    backgroundColor: "white",
                    border: "1px solid #c4c4c4",
                    padding: 0,
                    marginBottom: "65px"
                }}
            >
                <Box
                    id={`stageWrapper-${direction}`}
                    sx={{
                        backgroundColor: "white",
                        overflow: "hidden",
                        padding: "19px",
                    }}
                >
                    <Stage
                        ref={stageRef}
                        width={stageState.width}
                        height={stageState.height}
                        scaleX={stageState.scale}
                        scaleY={stageState.scale}
                        onMouseUp={handleClickStage}
                        onTouchEnd={handleClickStage}
                        style={
                            state.status === 'ADD_SHAPE'
                            ? 
                                { cursor: "copy" }
                            :   undefined
                        }
                    >
                        <Layer>
                            <Rect
                                fillPatternImage={image}
                                width={imageInfos.width}
                                height={imageInfos.height}
                                id="backgroundImage"
                                preventDefault={false}
                            />
                            { Object.keys(state.shapes).map((id) => {
                                isSelected = (id === state.selectedShapeId);
                                
                                eventsHandlers = {
                                    onSelect: null,
                                    onChange: null
                                };
                                
                                if(state.status === "SELECT_SHAPE") {
                                    eventsHandlers.onSelect = onShapeSelect;
                                } else if(isSelected) {
                                    eventsHandlers.onChange = onShapeChange;
                                }

                                return <Shape
                                    key={id}
                                    id={id}
                                    type={state.shapes[id].type}
                                    shapeProps={state.shapes[id].props}
                                    otherProps={{
                                        opacity: (
                                            (isSelected || state.status === null || state.status === "SELECT_SHAPE")
                                            ? 1 : 0.6
                                        ),
                                    }}
                                    stageDimensions={stageDimensions}
                                    isSelected={isSelected}
                                    eventsHandlers={eventsHandlers}
                                />
                            })}
                        </Layer>
                    </Stage>
                </Box>
            </Box>
            <Toolbar 
                status={state.status}
                shapeTypeToAddOrSelected={state.shapeTypeToAddOrSelected}
                handleAddShape={onAddShape}
                handleChangeStatus={onStatusChange}
            />
        </FullscreenDialog>
    );
}