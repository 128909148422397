import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import { Provider } from 'use-pouchdb'
import { testConnection, loginToRemoteDb, syncDatabases, initDatabase, localDatabaseHasBeenSetup } from './services/database'

import * as config from './config/customer.js'

import Screen from './components/UI/Screen';
import LoginForm from './components/LoginForm';

import ClientsHome from './components/Clients/Home'
import ClientProvider from './components/ClientProvider'
import ViewClient from './components/Clients/View'
import CreateClient from './components/Clients/Create'
import UpdateClient from './components/Clients/Update'

import ViewAnimal from './components/Animals/View'
import CreateAnimal from './components/Animals/Create'
import UpdateAnimal from './components/Animals/Update'

import ViewSession from './components/Sessions/View'
import CreateSession from './components/Sessions/Create'
import UpdateSession from './components/Sessions/Update'

import { Box } from '@mui/material';


const theme = createTheme({
    ...config.theme
});

function App() {
    const [online, isOnline] = useState(navigator.onLine);

    const [state, setState] = useState({
        status: 'loading',
        database: initDatabase()
    });

    const setOnline = () => {
        isOnline(true);
    };
    const setOffline = () => {
        isOnline(false);
    };


    // initiating listeners on mount:
    useEffect(() => {
        window.addEventListener('offline', setOffline);
        window.addEventListener('online', setOnline);

        return () => {
            window.removeEventListener('offline', setOffline);
            window.removeEventListener('online', setOnline);
        }
    }, []);

    useEffect(() => {
        if (online) {
            testConnection().then(status => {
                if (status === 'authorized') {
                    syncDatabases(state.database).then(localDb => {
                        setState({
                            status: status,
                            database: localDb
                        });
                    });
                } else {
                    setState(prevState => {
                        return {
                            ...prevState,
                            status: status
                        }
                    });
                }
            });
        } else {
            localDatabaseHasBeenSetup(state.database).then((setup) => {
                setState((prevState) => {
                    return {
                        ...prevState,
                        status: (setup ? "offline-authorized" : "unauthorized")
                    }
                });
            });
        }
    }, [online, state.database]);

    const handleLogin = (data) => {
        return new Promise((resolve, reject) => {
            loginToRemoteDb(data.username, data.password).then((remoteDb) => {
                syncDatabases(state.database, remoteDb).then(localDb => {
                    setState({
                        status: 'authorized',
                        database: localDb
                    });
                });
            }).catch((error) => {
                if (error.name === 'unauthorized' || error.name === 'forbidden') {
                    reject("Nom d'utilisateur ou mot de passe incorrect.");
                } else {
                    reject(error.message);
                }
            });
        });
    }

    if (state.status === 'loading') {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Screen
                        header={{ title: config.title }}
                        loading
                        loadingMessage="Synchronisation des données en cours, veuillez patienter."
                    />
                </ThemeProvider>
            </StyledEngineProvider>
        );
    } else if (state.status === 'unauthorized') {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Screen header={{ title: config.title }}>
                        {online
                            ?   <LoginForm handleLogin={handleLogin} />
                            :   <Box sx={{ textAlign: "center", color: "primary", mt: 3 }}>
                                    Veuillez vous connecter une première fois pour accéder à l'application.
                                </Box>
                        }
                    </Screen>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    } else if (state.status === 'authorized' || state.status === 'offline-authorized') {
        if (state.status === 'error') {
            // REPORT ERROR...
        } // else == 'offline' || 'authorized'

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Provider pouchdb={state.database}>
                        <BrowserRouter>
                            <Routes>
                                <Route path="" element={<ClientsHome />} />
                                <Route path="clients">
                                    <Route path="" element={<ClientsHome />} />
                                    <Route path="create" element={<CreateClient />} />
                                    <Route path=":clientId" element={<ClientProvider />}>
                                        <Route path="" element={<ViewClient />} />
                                        <Route path="update" element={<UpdateClient />} />
                                        <Route path="animals">
                                            <Route path="create" element={<CreateAnimal />} />
                                            <Route path=":animalId">
                                                <Route path="" element={<ViewAnimal />} />
                                                <Route path="update" element={<UpdateAnimal />} />
                                                <Route path="sessions">
                                                    <Route path="create" element={<CreateSession />} />
                                                    <Route path=":sessionId">
                                                        <Route path="" element={<ViewSession />} />
                                                        <Route path="update" element={<UpdateSession />} />
                                                    </Route>
                                                </Route>
                                            </Route>
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path="/" /> {/* 404 NOT FOUND */}
                            </Routes>
                        </BrowserRouter>
                    </Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

export default App;