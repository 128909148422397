import React, { useContext } from 'react'

import ClientContext from '../../../contexts/ClientContext';

import { List } from '@mui/material'

import SessionListItem from './ListItem';

export default function SessionsList({sessions}) {
    const { client, getCurrentAnimal } = useContext(ClientContext);
    
    const sortedSessions = Object.values(sessions);
    sortedSessions.sort(function(a, b) {
        return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
    });
    
    return (
        <List sx={{
            width: '100%',
            position: 'relative',
            overflow: 'auto',
            padding: 0
        }}>
            {sortedSessions.map((session) => 
                <SessionListItem
                    key={session._id}
                    session={session}
                    clientId={client._id}
                    animalId={getCurrentAnimal()._id}
                />
            )}
        </List>
    );
}