import React, { useState } from 'react';

import useImage from 'use-image';

import { DrawnReportEditor, DrawnReportPreview } from '.';

import { Box } from '@mui/material';

import { SESSION_SKELETON_IMAGES_INFOS } from '../../../config/sessions';


export default function DrawnReport({
    animalType,
    direction,
    sessionNumber,
    template = 'default',
    shapes = {},
    isSubmitting=false,
    onSubmit=null,
    onShapesSaved = null
}) {
    const [ mode, setMode ] = useState('preview'); // || 'editor'
    
    const skeletonImgSrc = '/assets/skeletonImgs/'+animalType+'/'+template+'/'+direction+'.jpg';

    //  To allow user to scroll screen on touch without opening editor :
    const [ touchStartPos, setTouchStartPos ] = useState({
        x: 0,
        y: 0
    });
    
    const [image] = useImage(skeletonImgSrc);
    const imageInfos = SESSION_SKELETON_IMAGES_INFOS[animalType][template];

    if(mode === 'preview') {
        const openEditor = () => {
            setMode('editor');
        };
        
        const onTouchStart = (e) => {
            setTouchStartPos({
                x: e.changedTouches[0].screenX,
                y: e.changedTouches[0].screenY,
            });
        };
        const onTouchEnd = (e) => {
            if(Math.abs(e.changedTouches[0].screenX-touchStartPos.x) < 3
            && Math.abs(e.changedTouches[0].screenY-touchStartPos.y) < 3) {
                setMode('editor');
            }
        };
        
        const onFormSubmit = (newImage) => {
            onSubmit(direction, newImage);
        };
        
        return (
            <Box
                onMouseUp={openEditor}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                style={{cursor: 'pointer'}}
            >
                <DrawnReportPreview
                    image={image}
                    imageInfos={imageInfos}
                    direction={direction}
                    shapes={shapes}
                    isSubmitting={isSubmitting}
                    onSubmit={onFormSubmit}
                />
            </Box>
        );
    } else {
        const closeEditor = () => { 
            setMode('preview');
        };

        const handleSaveShapes = (newShapes, newImage) => {
            onShapesSaved(direction, newShapes, newImage);
            setMode('preview');
        };

        return (
            <DrawnReportEditor
                image={image}
                imageInfos={imageInfos}
                direction={direction}
                shapes={shapes}
                dialogTitle={sessionNumber}
                handleSaveShapes={handleSaveShapes}
                handleClose={closeEditor}
            />
        );
    }
}