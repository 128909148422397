import React, { useState } from 'react';

import { Button, Menu, MenuList, MenuItem, ListItemText, ListItemIcon, Typography } from '@mui/material';

import AddShapeIcon from '@mui/icons-material/Add';
import SelectShapeIcon from '@mui/icons-material/TouchApp';

import LineIcon from '@mui/icons-material/Remove';
import CurvedLineIcon from '@mui/icons-material/LinearScaleOutlined';
import ArrowIcon from '@mui/icons-material/ArrowForward';
import CurvedArrowIcon from '@mui/icons-material/Redo';
import CrossOrCancelIcon from '@mui/icons-material/Clear';
import EllipseIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';



export default function EditorToolbar({
    status = null,
    shapeTypeToAddOrSelected = null,
    handleChangeStatus
}) {
    const [shapeMenuAnchorEl, setShapeMenuAnchorEl] = useState(null);

    const addShapeMenuOpen = Boolean(shapeMenuAnchorEl);;


    const handleClickAddShapeButton = (event) => {
        setShapeMenuAnchorEl(event.currentTarget);
    };
    const handleClickCancelAddShapeButton = () => {
        handleChangeStatus('CANCEL_ADD_SHAPE');
    };
    const handleClickSelectShapeButton = () => {
        handleChangeStatus('SELECT_SHAPE');
    };
    const handleClickCancelSelectShapeButton = () => {
        handleChangeStatus('CANCEL_SELECT_SHAPE');
    };
    const handleClickUnselectShapeButton = () => {
        handleChangeStatus('UNSELECT_SHAPE');
    }
    const handleClickDeleteShapeButton = () => {
        handleChangeStatus('SHAPE_DELETED');
    }

    const handleCloseAddShapeMenu = () => {
        setShapeMenuAnchorEl(null);
    };

    const addShape = (type) => {
        handleChangeStatus('ADD_SHAPE', {
            shapeType: type
        });
        setShapeMenuAnchorEl(null);
    };

    const handleClickAddLineButton = () => {
        addShape('line');
    };
    const handleClickAddCurvedLineButton = () => {
        addShape('curvedLine');
    };
    const handleClickAddArrowButton = () => {
        addShape('arrow');
    };
    const handleClickAddCurvedArrowButton = () => {
        addShape('curvedArrow');
    };
    const handleClickAddCrossButton = () => {
        addShape('cross');
    };
    const handleClickAddEllipseButton = () => {
        addShape('ellipse');
    };
    
    const getShapeTypeText = () => {
        switch(shapeTypeToAddOrSelected) {
            case 'line':
                return <>ligne&nbsp;<LineIcon/></>;
            case 'curvedLine':
                return  <>ligne courbée&nbsp;<CurvedLineIcon/></>;
            case 'arrow':
                return  <>flèche&nbsp;<ArrowIcon/></>;
            case 'curvedArrow':
                return  <>flèche courbée&nbsp;<CurvedArrowIcon/></>;
            case 'cross':
                return  <>croix&nbsp;<CrossOrCancelIcon/></>;
            case 'ellipse':
                return  <>ellipse&nbsp;<EllipseIcon/></>;
            default:
        }
    }
    switch(status) {
        case 'ADD_SHAPE':
            return (
                <div className="toolbar-wrapper">
                    <div className="toolbar">
                        <Typography className="toolbar-infos" color="primary">
                            Ajout d'une {getShapeTypeText()}
                        </Typography>
                        <Button
                            startIcon={<CrossOrCancelIcon/>}
                            onClick={handleClickCancelAddShapeButton}
                            className="toolbar-right-button"
                        >
                            Annuler
                        </Button>
                    </div>
                </div>
            );
        case 'SELECT_SHAPE':
            return (
                <div className="toolbar-wrapper">
                    <div className="toolbar">
                        <Typography className="toolbar-infos" color="primary">
                            Sélectionnez la forme à modifier
                        </Typography>
                        <Button
                            startIcon={<CrossOrCancelIcon/>}
                            onClick={handleClickCancelSelectShapeButton}
                            className="toolbar-right-button"
                        >
                            Annuler
                        </Button>
                    </div>
                </div>
            );
        case 'SHAPE_SELECTED':
            return (
                <div className="toolbar-wrapper">
                    <div className="toolbar">
                        <Typography className="toolbar-infos" color="primary">
                            Modifier {shapeTypeToAddOrSelected === "ellipse" ? "l'" : "la "}{getShapeTypeText()}
                        </Typography>
                        <Button
                            startIcon={<DeleteIcon/>}
                            onClick={handleClickDeleteShapeButton}
                            className="toolbar-right-button"
                        >
                            Supprimer
                        </Button>
                        <Button
                            startIcon={<CheckIcon/>}
                            onClick={handleClickUnselectShapeButton}
                            className="toolbar-right-button"
                        >
                            Terminer
                        </Button>
                    </div>
                </div>
            );
        default:
            return (
                <div className="toolbar-wrapper">
                    <div className="toolbar">
                        <Button
                            startIcon={<AddShapeIcon/>}
                            id="add-shape-button"
                            aria-controls="add-shape-menu"
                            aria-haspopup="true"
                            aria-expanded={addShapeMenuOpen ? 'true' : undefined}
                            onClick={handleClickAddShapeButton}
                            className={addShapeMenuOpen ? "add-shape-button-selected" : undefined}
                        >
                            Ajouter
                        </Button>
                        <Button
                            startIcon={<SelectShapeIcon/>}
                            onClick={handleClickSelectShapeButton}
                            disabled={addShapeMenuOpen ? true : undefined}
                        >
                            Modifier
                        </Button>
                        <Menu
                            id="add-shape-menu"
                            anchorEl={shapeMenuAnchorEl}
                            open={addShapeMenuOpen}
                            onClose={handleCloseAddShapeMenu}
                            variant="menu"
                            PopoverClasses={{
                                paper: "menu"
                            }}
                        >
                            <MenuList
                                aria-labelledby='add-shape-button'
                            >
                                <MenuItem onClick={handleClickAddLineButton}>
                                    <ListItemIcon>
                                        <LineIcon />
                                    </ListItemIcon>
                                    <ListItemText>Ligne</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleClickAddCurvedLineButton}>
                                    <ListItemIcon>
                                        <CurvedLineIcon />
                                    </ListItemIcon>
                                    <ListItemText>Ligne courbe</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleClickAddArrowButton}>
                                    <ListItemIcon>
                                        <ArrowIcon />
                                    </ListItemIcon>
                                    <ListItemText>Flèche</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleClickAddCurvedArrowButton}>
                                    <ListItemIcon>
                                        <CurvedArrowIcon />
                                    </ListItemIcon>
                                    <ListItemText>Flèche courbe</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleClickAddCrossButton}>
                                    <ListItemIcon>
                                        <CrossOrCancelIcon />
                                    </ListItemIcon>
                                    <ListItemText>Croix</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleClickAddEllipseButton}>
                                    <ListItemIcon>
                                        <EllipseIcon />
                                    </ListItemIcon>
                                    <ListItemText>Ellipse</ListItemText>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </div>
                </div>
            );
    }
}