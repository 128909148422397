import React, { useState } from 'react';
import { Line, Shape } from 'react-konva';
import Anchor from './Anchor';

export default function CurvedLine({
    id,
    shapeProps,
    otherProps,
    stageDimensions,
    isSelected = false,
    onSelect = null,
    onChange = null
}) {
    const shapeRef = React.useRef();
    
    const [linePoints, setLinePoints] = useState(shapeProps.points);

    const onAnchorDragMove = (target, x, y) => {
        const newLinePoints = [...linePoints];

        if      (x < 25)                            { x = 25; }
        else if (x > stageDimensions.width - 25)    { x = stageDimensions.width - 25; }

        if      (y < 25)                            { y = 25; }
        else if (y > stageDimensions.height - 25)   { y = stageDimensions.height - 25;  }

        if(target === 1) {
            newLinePoints[0] = x;
            newLinePoints[1] = y;
        } else if(target === 2) {
            newLinePoints[2] = x;
            newLinePoints[3] = y;
        } else {
            newLinePoints[4] = x;
            newLinePoints[5] = y;
        }

        setLinePoints(newLinePoints);
    };

    const onAnchorDragEnd = (target, x, y) => {
        const newLinePoints = [...linePoints];

        if      (x < 25)                            { x = 25; }
        else if (x > stageDimensions.width - 25)    { x = stageDimensions.width - 25; }

        if      (y < 25)                            { y = 25; }
        else if (y > stageDimensions.height - 25)   { y = stageDimensions.height - 25;  }
        
        if(target === 1) {
            newLinePoints[0] = Math.round(x);
            newLinePoints[1] = Math.round(y);
        } else if(target === 2) {
            newLinePoints[2] = Math.round(x);
            newLinePoints[3] = Math.round(y);
        } else {
            newLinePoints[4] = Math.round(x);
            newLinePoints[5] = Math.round(y);
        }

        onChange(id, {
            ...shapeProps,
            points: newLinePoints
        });
    };

    const handleSelectShape = () => {
        onSelect(id);
    };
    const onSelectShapeHandler = (onSelect ? handleSelectShape : undefined);

    return (
        <>
        <Shape
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(linePoints[0], linePoints[1]);
                context.quadraticCurveTo(
                    linePoints[2],
                    linePoints[3],
                    linePoints[4],
                    linePoints[5]
                );
                context.fillStrokeShape(shape);
            }}
            onClick={onSelectShapeHandler}
            onTap={onSelectShapeHandler}
            ref={shapeRef}
            {...shapeProps}
            {...otherProps}
        />
        {isSelected && (
            <>
            <Line
                dash={[10, 10, 0, 10]}
                strokeWidth={3}
                stroke="black"
                lineCap="round"
                opacity={0.3}
                points={linePoints}
            />
            <Anchor
                number={1}
                x={linePoints[0]}
                y={linePoints[1]}
                onDragMove={onAnchorDragMove}
                onDragEnd={onAnchorDragEnd}
            />
            <Anchor
                number={2}
                x={linePoints[2]}
                y={linePoints[3]}
                onDragMove={onAnchorDragMove}
                onDragEnd={onAnchorDragEnd}
            />
            <Anchor
                number={3}
                x={linePoints[4]}
                y={linePoints[5]}
                onDragMove={onAnchorDragMove}
                onDragEnd={onAnchorDragEnd}
            />
            </>
        )}
        </>
    );
}