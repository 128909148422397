import React, { useContext } from 'react'
import { List } from '@mui/material'

import AnimalListItem from './ListItem';
import ClientContext from '../../../contexts/ClientContext';

export default function AnimalsList({animals}) {
    const {
        client
    } = useContext(ClientContext);

    return (
        <List sx={{
            width: '100%',
            position: 'relative',
            overflow: 'auto',
            padding:0
        }}>
            {animals && Object.values(animals).map((animal) => 
                <AnimalListItem
                    animal={animal}
                    key={animal._id}
                    clientId={client._id}
                />
            )}
        </List>
    );
}