import React from 'react';

import { Box } from '@mui/material';

export default function DrawnReportPreviewImage({image, direction}) {
    return (
        <Box
            id={`stageWrapper-${direction}`}
            sx={{
                border: "1px solid #c4c4c4",
                borderRadius: "4px",
                overflow: "hidden",
                padding: "8px"
            }}
        >
            <Box component="img"
                src={image}
                alt={direction === "right" ? "flan droit" : "flan gauche"}
                sx={{ width: "100%"  }}
            />
        </Box>
    );
}