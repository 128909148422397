import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

export default function ConfirmDialog({
    open,
    children,       //message
    onCancel,
    onConfirm,
    title = "Êtes-vous sûr(e) ?",
    confirmButtonLabel = "Oui, continuer",
    cancelButtonLabel = "Non, annuler",
    confirmButtonColor = "primary",
    cancelButtonColor = "cancel"
}) { 
    return (
        <Dialog
          open={open}
          onClose={onCancel}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
        >
            <DialogTitle id="confirm-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    color={cancelButtonColor}
                >
                    {cancelButtonLabel}
                </Button>
                <Button
                    onClick={onConfirm}
                    color={confirmButtonColor}
                >
                    {confirmButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
// autoFocus