import React from 'react'

import { IconButton, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, Tooltip, colors } from '@mui/material'

import { Link as RouterLink, useNavigate } from 'react-router-dom'

import EditIcon from '@mui/icons-material/Edit';

import ReportGeneratedIcon from '@mui/icons-material/CloudDone';
import ReportGeneratingIcon from '@mui/icons-material/CloudSync';
//import ReportNotGeneratedIcon from '@mui/icons-material/CloudOff';

import ReportSentIcon from '@mui/icons-material/Send';
import ReportSendingIcon from '@mui/icons-material/ScheduleSend';
import ReportNotSentIcon from '@mui/icons-material/CancelScheduleSend';

import { sessionPath, updateSessionPath } from '../../../../services/routesPaths';

import { options as config } from '../../../../config/customer.js';

export default function SessionListItem({session, clientId, animalId}) {
    let navigate = useNavigate();

    const handleEditSession = () => {
        navigate(updateSessionPath(clientId, animalId, session._id));
    };

    const date = session.date.split('-');
    
    const renderReportStatusIcon = () => {
        if(session.generateReport) {
            return  <Tooltip title="Rapport en cours de génération">
                        <ReportGeneratingIcon 
                            style={{
                                color: colors.blue[600],
                                opacity: 0.7
                            }}
                        />
                    </Tooltip>;
        } else {
            if(!config.sendReports) {
                return  <Tooltip title="Rapport généré">
                            <ReportGeneratedIcon 
                                style={{
                                    color: colors.green[600],
                                    opacity: 0.9
                                }}
                            />
                        </Tooltip>;
            } else {
                if(session.sendReport) {
                    return  <Tooltip title="Rapport généré, en cours d'envoi">
                                <ReportSendingIcon 
                                    style={{
                                        color: colors.blue[600],
                                        opacity: 0.7
                                    }}
                                />
                            </Tooltip>;
                } else if(session.reportSent) {
                    return  <Tooltip title="Rapport généré et envoyé">
                                <ReportSentIcon 
                                    style={{
                                        color: colors.green[600],
                                        opacity: 0.9
                                    }}
                                />
                            </Tooltip>;
                } else {
                    return  <Tooltip title="Rapport généré mais non envoyé">
                                <ReportNotSentIcon 
                                    style={{
                                        color: colors.orange[600],
                                        opacity: 0.5
                                    }}
                                />
                            </Tooltip>;
                }
            }
        }
    };

    return (
        <ListItem
            component={RouterLink}
            to={sessionPath(clientId, animalId, session._id)}
            className='list-item session-list-item'
        >
            <ListItemIcon sx={{ minWidth: '40px' }}>
                {renderReportStatusIcon()}
            </ListItemIcon>
            <ListItemText primary={`${date[2]}/${date[1]}/${date[0]} - ${session.number}`}/>
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={handleEditSession} size="large">
                    <EditIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}