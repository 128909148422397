import React from 'react';

//import { AppBar, Toolbar, Typography, List, ListItem, ListItemText, Container, Hidden } from '@mui/material'
import { AppBar, Toolbar, Typography, Container, Box } from '@mui/material'

//import SideDrawer from '../SideDrawer'
import Breadcrumbs from '../Breadcrumbs';

/*
const navLinks = [
    //{ title: `Séances`, path: `/sessions` },
    { title: `Clients`, path: `/clients` },
    //{ title: `Paramètres`, path: `/settings` },
];
*/

export default function Header({titleIcon, title, breadcrumbs}) {
    return (
        <AppBar position="sticky" sx={breadcrumbs && {boxShadow: "none", borderBottom: "1px solid white"}}>
            <Toolbar sx={{
                paddingLeft: "15px !important",
                paddingRight: "15px !important"
            }}>
                <Container sx={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    paddingLeft: "0 !important",
                    paddingRight: "0 !important"
                }}>
                    <Container sx={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: "0 5px 0 0 !important",
                    }}>
                        <Box component="img"
                            src="/assets/logo.png"
                            alt="logo"
                            sx={{
                                display: `flex`,
                                height: `48px`,
                                maxWidth: `100px`,
                                marginRight: `10px`
                            }}
                        />
                        <Typography variant="h6" sx={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: "0 38px 0 0",
                            "& svg": { marginRight: "5px"  }
                        }}>
                            {titleIcon && titleIcon}
                            {title}
                        </Typography>
                    </Container>
                </Container>
            </Toolbar>
            {breadcrumbs && <Breadcrumbs {...breadcrumbs} /> }
        </AppBar>
    );
}