import format from "date-fns/format";

import { SESSION_DOC_PREFIX } from "../config/sessions";
import { slugify } from "../services/helpers";
import { updateClient } from "./clients";

import { sessions as sessionsConfig } from '../config/customer.js';

export function createSession(db, client, animalId, session) {
    return new Promise((resolve, reject) => {
        const sessionId = SESSION_DOC_PREFIX + Date.now();

        client.updateTarget = {
            action: "session_create",
            animalId: animalId,
            sessionId: sessionId
        };

        for(const i in client.animals) {
            if(animalId === client.animals[i]._id) {
                //  Adding session to animal with default values
                client.animals[i].sessions.push({
                    _id: sessionId,
                    created: new Date().toISOString(),
                    ...session,
                    generateReport: true,
                    sendReport: false,
                    reportSent: false
                });

                //  Updating animal lastSessionDate infos
                client.animals[i].lastSessionDate = session.date;
                client.animals[i].newsRequestStatus = null;
                client.animals[i].checkupReminderStatus = null;

                break;
            }
        }

        updateClient(db, client).then(response => {
            resolve(sessionId);
            //resolve(client);
        }).catch(function (err) {
            console.log(err);
            reject(err);
        });
    });
}

export function updateSession(db, client, animalId, sessionId, sessionInfos) {
    return new Promise((resolve, reject) => {
        client.updateTarget = {
            action: "session_update",
            animalId: animalId,
            sessionId: sessionId
        };

        for(const i in client.animals) {
            if(animalId === client.animals[i]._id) {
                for(const j in client.animals[i].sessions) {
                    if(sessionId === client.animals[i].sessions[j]._id) {
                        client.animals[i].sessions[j] = {
                            ...client.animals[i].sessions[j],
                            ...sessionInfos,
                            generateReport: true
                        };
                        break;
                    }
                }
                break;
            }
        }
        
        updateClient(db, client).then(response => {
            resolve(true);
            //resolve(client);
        }).catch(function (err) {
            console.log(err);
            reject(err);
        });
    });
}


export function deleteSession(db, client, animalId, sessionId) {
    return new Promise((resolve, reject) => {
        client.updateTarget = {
            action: "session_delete",
            animalId: animalId
        };
        
        for(const i in client.animals) {
            if(animalId === client.animals[i]._id) {
                for(const j in client.animals[i].sessions) {
                    if(sessionId === client.animals[i].sessions[j]._id) {
                        client.updateTarget.reportNumber = client.animals[i].sessions[j].number;

                        delete client.animals[i].sessions.splice(j, 1);
                        break;
                    }
                }
                break;
            }
        }

        updateClient(db, client).then(response => {
            resolve(true);
            //resolve(client);
        }).catch(function (err) {
            console.log(err);
            reject(err);
        });
    });
}

export function sendSessionReport(db, client, animalId, sessionId) {
    return new Promise((resolve, reject) => {
        client.updateTarget = {
            action: "session_update",
            animalId: animalId,
            sessionId: sessionId
        };
        
        for(const i in client.animals) {
            if(animalId === client.animals[i]._id) {
                for(const j in client.animals[i].sessions) {
                    if(sessionId === client.animals[i].sessions[j]._id) {
                        client.animals[i].sessions[j].sendReport = true;
                        break;
                    }
                }
                break;
            }
        }

        updateClient(db, client).then(response => {
            resolve(true);
            //resolve(client);
        }).catch(function (err) {
            console.log(err);
            reject(err);
        });
    });
}

export function generateSessionNumber(clientName, animalName, date, reason, uniqId) {
    let sessionNumber = sessionsConfig.numberFormat;

    if(clientName !== null) {
        sessionNumber = sessionNumber.replace('{clientName}', slugify(clientName).toUpperCase());
    }
    if(animalName !== null) {
        sessionNumber = sessionNumber.replace('{animalName}', slugify(animalName).toUpperCase());
    }
    if(date !== null) {
        sessionNumber = sessionNumber.replace('{date}', format(date, sessionsConfig.numberFormatDateFormat));
    }
    if(reason !== null) {
        sessionNumber = sessionNumber.replace('{reason}', slugify(reason).toUpperCase());
    }
    if(uniqId !== null) {
        sessionNumber = sessionNumber.replace('{uniqId}', uniqId);
    }
    return sessionNumber;
}