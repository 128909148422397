import React from 'react';

import { Container, Box, CircularProgress, Typography } from '@mui/material'

import Header from '../Header';

export default function Screen( props ) {
    return (
        <>
        <Header {... props.header} />
        {
            props.loading
            ?   <Container className={"fullSizeCenteredContainer"}>
                    <CircularProgress />
                    {props.loadingMessage &&
                        <Box sx={{ textAlign: "center", flexDirection: "column", mt: 3 }}>
                            <Typography color="secondary">{props.loadingMessage}</Typography>
                        </Box>
                    }
                </Container>
            :   <Container className="pageContainer noPadding">
                    {props.children}
                </Container>
        }
        </>
    );
}