import React from 'react';

import { options, animals as animalsConfig } from '../../../config/customer.js';

import { Grid, InputAdornment, TextField, Typography, Divider } from '@mui/material';

import { getAnimalAgeStr, getAnimalDeathAgeStr, getAnimalTypeDisplayName } from '../../../models/animals';
import { ANIMALS_SEXES, STERILIZED_OPTIONS } from '../../../config/animals';
import TombstoneIcon from '../../UI/icons/TombstoneIcon';

import { CHECKUP_REMINDER } from '../../../config/animals';

export default function AnimalInfos({animal}) {
    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="name"
                    label="Nom"
                    value={animal.name}
                    variant="standard"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="sex"
                    label="Sexe"
                    value={ANIMALS_SEXES[animal.sex]}
                    variant="standard"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="type"
                    label="Type"
                    value={getAnimalTypeDisplayName(animal.type)}
                    variant="standard"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="race"
                    label="Race"
                    value={animal.race}
                    variant="standard"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="birthYear"
                    label="Année de naissance"
                    value={animal.birthYear ?? ''}
                    variant="standard"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6}>
            {animal.deceased
            ?
                <TextField
                    id="deathAge"
                    label="Année de décès"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><TombstoneIcon /></InputAdornment>,
                    }}
                    value={getAnimalDeathAgeStr(animal.birthYear, animal.deathYear)}
                    variant="standard"
                    disabled
                />
            : 
                <TextField
                    id="age"
                    label="Âge"
                    value={getAnimalAgeStr(animal.birthYear)}
                    variant="standard"
                    disabled
                />
            }
            </Grid>
            {animalsConfig.sterilized &&
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="sterilized"
                        label="Stérilisée/Castré"
                        value={STERILIZED_OPTIONS[animal.sterilized]}
                        variant="standard"
                        disabled
                    />
                </Grid>
            }
            {animalsConfig.veterinary &&
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="veterinary"
                        label="Vétérinaire usuel"
                        value={animal.veterinary}
                        variant="standard"
                        disabled
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <TextField
                    name="notes"
                    label="Notes"
                    defaultValue={animal.notes ?? ''}
                    variant="standard"
                    multiline
                    disabled
                />
            </Grid>
            {(animalsConfig.privateNotes && animal.privateNotes) &&
                <Grid item xs={12}>
                    <TextField
                        name="privateNotes"
                        label="Notes privées"
                        defaultValue={animal.privateNotes}
                        variant="standard"
                        multiline
                        disabled
                    />
                </Grid>
            }
        </Grid>
        {options.checkupReminders &&
        <>
            <Divider className="divider" />
            <Typography className="detailsCardTitle" color="primary" gutterBottom>
                Préférences
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="checkupReminder"
                        label="Rappel check-up"
                        value={CHECKUP_REMINDER[animal.checkupReminder]}
                        variant="standard"
                        disabled
                    />
                </Grid>
            </Grid>
            <Divider className="divider-prebuttons" />
        </>
        }
        </>
    );
}