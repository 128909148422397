import React from 'react'

import { Typography, IconButton, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material'

import { Link as RouterLink, useNavigate } from 'react-router-dom'

import EditIcon from '@mui/icons-material/Edit';

import { ANIMALS_TYPES } from "../../../../config/animals"
import { animalPath, updateAnimalPath } from '../../../../services/routesPaths';
import TombstoneIcon from '../../../UI/icons/TombstoneIcon';

export default function AnimalListItem({animal, clientId}) {
    let navigate = useNavigate();

    const handleEditAnimal = () => {
        navigate(updateAnimalPath(clientId, animal._id));
    };
    
    return (
        <ListItem
            className={animal.deceased ? "list-item animal-list-item animal-deceased-list-item" : "list-item animal-list-item"}
            component={RouterLink}
            to={animalPath(clientId, animal._id)}
        >
            <ListItemText
                primary={
                    <Typography>
                        {ANIMALS_TYPES[animal.type].icon}
                        &nbsp;&nbsp;
                        {animal.name}
                        {animal.deceased && <TombstoneIcon size="1rem" />}
                    </Typography>
                }
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={handleEditAnimal} size="large">
                    <EditIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}