import React, { useEffect, useState } from 'react';
import { Circle } from 'react-konva';

export default function Anchor({number, x, y, onDragMove, onDragEnd}) {
    const [state, setState] = useState('inactive');

    const setActive = () => {
        setState('active')
    };

    const setInactive = () => {
        setState('inactive')
    };

    useEffect(() => {
        if(state === 'active') {
            document.body.style.cursor = 'grab';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [state]);

    return (
        <Circle
            x={x}
            y={y}
            radius={20}
            strokeWidth={(state === "active") ? 4 : 2}
            stroke="#666"
            fill="#ddd"
            draggable
            onDragMove={e => onDragMove(number, e.target.x(), e.target.y())}
            onDragEnd={e => onDragEnd(number, e.target.x(), e.target.y())}
            onMouseOver={setActive}
            onMouseOut={setInactive}
            onTouchStart={setActive}
            onTouchEnd={setInactive}
        />
    );
}
// onDragEnd={e => onDragEnd(number, e.target.x(), e.target.y())}