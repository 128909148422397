import React from 'react'
import { Box, Dialog, Slide, AppBar, Container, Toolbar, Button, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullscreenDialog({
    open = true,
    title = '',
    icon = '',
    leftButton = null,
    rightButton = null,
    backButton = null,
    onClose = null,
    containerClassName = null,
    children
}) { 
    const generateButton = (button) => {
        if(!button.hasOwnProperty("label")) {
            return (
                <IconButton
                    color="inherit"
                    onClick={button.onClick}
                    aria-label={button?.ariaLabel}
                    size="large"
                    disabled={button?.disabled ?? undefined}
                >
                    {button?.icon}
                </IconButton>
            );
        } else if(button.hasOwnProperty("icon")) {
            return  <Button
                        color="inherit"
                        startIcon={button.icon}
                        onClick={button.onClick}
                        disabled={button?.disabled ?? undefined}
                    >
                        {button?.icon} {button?.label}
                    </Button>
        } else {
            return  <Button
                        color="inherit"
                        startIcon={button.icon}
                        onClick={button.onClick}
                        disabled={button?.disabled ?? undefined}
                    >
                        {button?.icon} {button?.label}
                    </Button>
        }
    };
    
    return (
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            PaperProps={{ style: { backgroundColor: '#f0f0f0', boxShadow: 'none' } } }
        >
            <AppBar position="sticky">
                <Toolbar>
                    <Container sx={{
                        display: `flex`,
                        justifyContent: `space-between`,
                        paddingLeft: "0 !important",
                        paddingRight: "0 !important"
                    }}>
                        {leftButton ?
                            generateButton(leftButton)
                        : backButton &&
                            <Button
                                color="inherit"
                                startIcon={<ChevronLeftIcon />}
                                onClick={backButton.onClick}
                                sx={{ marginLeft: '-15px' }}
                            >
                                {backButton?.icon} {backButton?.label}
                            </Button>
                        }
                        <Box component="div" sx={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden'
                        }}>
                            {icon}
                            <Typography
                                variant="h6"
                                sx={{marginLeft: (theme) => theme.spacing(2)}}
                            >
                                {title}
                            </Typography>
                        </Box>
                        {rightButton ?
                            generateButton(rightButton)
                        :
                            <IconButton color="inherit" onClick={onClose} aria-label="Fermer" size="large">
                                <CloseIcon />
                            </IconButton>
                        }
                    </Container>
                </Toolbar>
            </AppBar>
            <Container
                sx={{ padding: '15px !important' }}
                className={containerClassName}
            >
                {children}
            </Container>
        </Dialog>
    );
}