import React from 'react';
import { Star } from 'react-konva';

export default function Cross({
    id,
    shapeProps,
    otherProps,
    stageDimensions,
    isSelected = false,
    onSelect = null,
    onChange = null
}) {
    const shapeRef = React.useRef();

    const handleSelectShape = () => {
        onSelect(id);
    };
    
    const onDragEnd = (event) => {
        let x = event.target.x();
        let y = event.target.y();
        
        if      (x < 30)                            { x = 30; }
        else if (x > stageDimensions.width - 30)    { x = stageDimensions.width - 30; }

        if      (y < 30)                            { y = 25; }
        else if (y > stageDimensions.height - 30)   { y = stageDimensions.height - 30;  }

        onChange(id, {
            ...shapeProps,
            x: Math.round(x),
            y: Math.round(y),
        });
    };
    
    const shapeEvents = { };

    if(onSelect) {
        shapeEvents.onClick         = handleSelectShape;
        shapeEvents.onTap           = handleSelectShape;
        shapeEvents.draggable       = false;
    } else if(onChange) {
        if(isSelected) {
            shapeEvents.draggable   = true;
            shapeEvents.onDragEnd   = onDragEnd;
        } else {
            shapeEvents.draggable   = false;
        }
    }

    return (
        <>
        <Star
            ref={shapeRef}
            {...shapeProps}
            {...otherProps}
            {...shapeEvents}
        />
        {/*isSelected && (
        )*/}
        </>
    );
}