import { CLIENT_DOC_PREFIX, NB_CLIENTS_PER_FETCH } from "../config/clients"
import { formatName } from "../services/helpers";
import { search as searchConfig } from '../config/customer.js'

export function fetchClients(db, from, search) {
    const selector = {};

    if(typeof from === "undefined" || !from) {
        selector.name = {'$gt': "" };
    } else {
        selector.name = {'$gt': from};
    }

    if(typeof search !== "undefined" && search !== "") {
        const regex = new RegExp('.*' + search + '.*', "i");

        let field;

        let hasAnimalsSelector = searchConfig.fields.hasOwnProperty("animals");
        const nbAnimalsSelectors = searchConfig.fields.animals.length;

        if(nbAnimalsSelectors < 1) {
            hasAnimalsSelector = false;
        }

        if(searchConfig.fields.clients.length === 1 && !hasAnimalsSelector) {
            selector[searchConfig.fields.clients[0]] = { '$regex': regex  };
        } else {
            selector.$or = [];

            for(field of searchConfig.fields.clients) {
                selector.$or.push({[field]: { '$regex': regex } });
            }
            
            if(hasAnimalsSelector) {
                const animalMatch = {};

                if(nbAnimalsSelectors === 1) {
                    animalMatch[searchConfig.fields.animals[0]] = { '$regex': regex };
                } else {
                    animalMatch.$or = [];

                    for(field of searchConfig.fields.animals) {
                        animalMatch.$or.push({[field]: { '$regex': regex } });
                    }
                }

                selector.$or.push({
                    "animals": {
                        '$elemMatch': animalMatch
                    }
                });
            }
        }
    } else {
        selector.$or = [
            { name: { '$regex': /.*/ } }
        ];
    }
    
    return new Promise((resolve, reject) => {
        db.createIndex({
            index: {
                fields: ["name"]
            }
        }).then(() => {
            db.find({
                selector: selector,
                sort: ['name'],
                limit: NB_CLIENTS_PER_FETCH
            }).then(function (result) {
                resolve(result.docs);
            }).catch(function (err) {
                console.log(err);
                reject(err);
            });
        }).catch(err => {
            console.log(err);
            reject(err);
        });
    });
}

export function fetchClient(db, id) {
    return new Promise((resolve, reject) => {
        id = CLIENT_DOC_PREFIX + id;
        
        db.get(id).then(function (doc) {
            resolve(doc);
        }).catch(err => {
            console.log(err);
            reject(err);
        });
    });
}

export function createClient(db, client) {
    return new Promise((resolve, reject) => {
        const now = Date.now();
        const id = CLIENT_DOC_PREFIX + now;

        client = formatClient(client);
        
        db.put({
            _id: id,
            created: new Date().toISOString(),
            ...client,
            animals: []
        }).then(result => {
            resolve(id)
        }).catch(err => {
            console.log(err);
            reject(err);
        });
    });
}

export function updateClient(db, client) {
    return new Promise((resolve, reject) => {
        client = formatClient(client);
        
        db.put(
            client
        ).then(result => {
            resolve(result.rev)
        }).catch(err => {
            console.log(err);
            reject(err);
        });
    });
}

export function deleteClient(db, client) {
    return new Promise((resolve, reject) => {
        db.remove(
            client
        ).then(result =>
            resolve(true)
        ).catch(err => {
            console.log(err);
            reject(err);
        });
    });
}

function formatClient(client) {
    client.firstname = formatName(client.firstname, true);
    client.lastname = formatName(client.lastname, true);
    client.name = client.lastname + " " + client.firstname;

    client.city = formatName(client.city, true);

    return client;
}