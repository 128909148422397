import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { Stage, Layer, Rect } from 'react-konva';

import Shape from '../Shapes/Shape';

export default function DrawnReportPreview({
    image,
    imageInfos,
    direction,
    shapes = {},
    isSubmitting = false,
    onSubmit = null
}) {
    const [stageState, setStageState ] = useState({
        width: 0,
        height: 0,
        scale: 1
    });
    
    const stageRef = React.useRef(null);

    useEffect(() => {
        const updateStateStage = () => {
            const stageWrapper = document.getElementById('stageWrapper-'+direction);

            if(stageWrapper == null) {
                return;
            }
            const scale = (document.getElementById('stageWrapper-'+direction).offsetWidth-16) / imageInfos.width;
        
            setStageState({
                width: imageInfos.width * scale,
                height: imageInfos.height * scale,
                scale: scale
            });
        };
        
        window.addEventListener('resize', updateStateStage);

        // Giving some time to UI dialog to finish animation and load stage
        const initStage = setInterval(() => {
            if(document.getElementById('stageWrapper-'+direction) !== undefined) {
                updateStateStage();
                clearInterval(initStage);
            }
        }, 1);


        if(isSubmitting) {
            onSubmit(stageRef.current.toDataURL({
                mimeType: "image/jpeg",
                quality: 0.6,
                pixelRatio: 1/stageState.scale
            }));
        }

        return () => {
            window.removeEventListener('resize', updateStateStage);
        }
    }, [shapes, direction, imageInfos, isSubmitting, onSubmit, stageRef, stageState]);
    
    const stageDimensions = {
        width: imageInfos.width,
        height: imageInfos.height
    };

    return (
        <Box
            id={`stageWrapper-${direction}`}
            sx={{
                border: "1px solid #c4c4c4",
                borderRadius: "4px",
                overflow: "hidden",
                padding: "8px"
            }}
        >
            <Stage
                ref={stageRef}
                width={stageState.width}
                height={stageState.height}
                scaleX={stageState.scale}
                scaleY={stageState.scale}
            >
                <Layer>
                    <Rect
                        fillPatternImage={image}
                        width={imageInfos.width}
                        height={imageInfos.height}
                        id="backgroundImage"
                        stageDimensions={stageDimensions}
                        preventDefault={false}
                    />
                    { Object.keys(shapes).map((id) => {
                        return <Shape
                            key={id}
                            id={id}
                            type={shapes[id].type}
                            shapeProps={shapes[id].props}
                            stageDimensions={stageDimensions}
                        />
                    })}
                </Layer>
            </Stage>
        </Box>
    );
}