import React from 'react';

import { Grid, Card, CardContent, CardActions, Button, Typography, Divider } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom'

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { Select, TextField } from '../../UI/Forms';

import * as yup from 'yup';

import { REQUIRED_FIELD_ERROR_MESSAGE} from '../../../config/forms'
import { CONTACT_MODES } from '../../../config/clients';

const contactModeOptions = [], contactModeOptionsValues = [''];

for (const value in CONTACT_MODES) {
    contactModeOptions.push({ value: value, name: CONTACT_MODES[value], disabled: (value !== 'none' && value !== 'email') });
    contactModeOptionsValues.push(value);
}

const validationSchema = yup.object().shape({
    lastname: yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
    firstname: yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
    email: yup.string().email('Adresse mail invalide.'),
    phone: yup.string(),
    company: yup.string(),
    contactMode: yup.string()
                    .required(REQUIRED_FIELD_ERROR_MESSAGE)
                    .oneOf(contactModeOptionsValues, 'Type de mode de contact invalide.')
});

export default function Form({values, onSubmit, cancelButtonProps}) {
    const methods = useForm({
        mode: "onBlur",
        resolver: yupResolver(validationSchema)
    });

    const { handleSubmit, formState: { errors } } = methods;
    
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Card elevation={3}>
                    <CardContent>
                        <Typography className="detailsCardTitle" color="primary" gutterBottom>
                            Informations personnelles 
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="lastname"
                                    label="Nom"
                                    values={values}
                                    errors={errors}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="firstname"
                                    label="Prénom"
                                    values={values}
                                    errors={errors}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="email"
                                    label="Adresse email"
                                    values={values}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="phone"
                                    label="Téléphone"
                                    values={values}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="address"
                                    label="Adresse"
                                    values={values}
                                    errors={errors}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Grid container item xs={12} sm={6} sx={{
                                margin: '0'
                            }}>
                                <Grid item xs={4} sm={12}>
                                    <TextField
                                        name="postcode"
                                        label="Code postal"
                                        values={values}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid item xs={8} sm={12} sx={{
                                    alignSelf: 'flex-end',
                                    '@media (min-height:599px': { paddingLeft: '10px' }
                                }}>
                                    <TextField
                                        name="city"
                                        label="Ville"
                                        values={values}
                                        errors={errors}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="company"
                                    label="Entreprise"
                                    values={values}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        <Divider className="divider" />
                        <Typography className="detailsCardTitle" color="primary" gutterBottom>
                            Préférences de communication
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    name="contactMode"
                                    label="Mode de contact"
                                    options={contactModeOptions}
                                    values={values}
                                    defaultValue="email"
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        <Divider className="divider-prebuttons" />
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="contained"
                            color="cancel"
                            startIcon={<CancelIcon />}
                            component={RouterLink}
                            {...cancelButtonProps}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            startIcon={<SaveIcon />}
                            onClick={handleSubmit}
                        >
                            Enregistrer
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </FormProvider>
    );
}