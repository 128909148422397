import React, { useState } from 'react'

import { Button, IconButton, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material'

import { Link as RouterLink, useNavigate } from 'react-router-dom'

import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';

import { animalPath, clientPath } from '../../../../services/routesPaths';

import { ANIMALS_TYPES } from "../../../../config/animals"
import TombstoneIcon from '../../../UI/icons/TombstoneIcon';

export default function ClientListItem({client}) {
    let animals = '';
    if(client.animals) {
        animals = Object.values(client.animals).map((animal) => 
            <Button
                component={RouterLink}
                key={animal._id}
                to={animalPath(client._id, animal._id)}
                className={animal.deceased ? "animal-link animal-link-deceased" : "animal-link"}
            >
                {ANIMALS_TYPES[animal.type].icon + ' ' + animal.name}
                {animal.deceased && <TombstoneIcon size="1rem" />}
            </Button>
        );
    }
    
    const navigate = useNavigate();

    //  To allow user to scroll screen on touch without opening client page :
    const [ touchStartPos, setTouchStartPos ] = useState({
        x: 0,
        y: 0
    });

    const onTouchStart = (e) => {
        setTouchStartPos({
            x: e.changedTouches[0].screenX,
            y: e.changedTouches[0].screenY,
        });
    };
    const onTouchEnd = (e) => {
        if(Math.abs(e.changedTouches[0].screenX-touchStartPos.x) < 3
        && Math.abs(e.changedTouches[0].screenY-touchStartPos.y) < 3) {
            navigateToClientPage(e);
        }
    };

    const navigateToClientPage = (e) => {
        if(!e.target.classList.contains("MuiButton-root")) {
            navigate(clientPath(client._id));
        }
    }
    
    return (
        <ListItem
            onMouseUp={navigateToClientPage}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            className="list-item client-list-item"
        >
            <ListItemText
                primary={client.lastname.toUpperCase() + ' ' + client.firstname + (client.company ? ' ('+client.company+')' : '')}
                secondary={animals}
                className="client-list-item-text"
            />
            <ListItemSecondaryAction>
                {/*}
                <IconButton edge="end" aria-label="edit" onClick={handleEditClient}>
                    <EditIcon />
                </IconButton>
                */}
                <a href={`mailto:${client.email}`}>
                    <IconButton edge="end" aria-label="email" size="large">
                        <MailIcon />
                    </IconButton>
                </a>
                <a href={`tel:${client.phone}`}>
                    <IconButton edge="end" aria-label="phone" size="large">
                        <PhoneIcon />
                    </IconButton>
                </a>
            </ListItemSecondaryAction>
        </ListItem>
    );
}