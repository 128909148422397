import React from 'react';

import { createRoot } from 'react-dom/client';

import './index.css';

import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));
root.render(<App />);

serviceWorkerRegistration.register({
    onUpdate: () => {
        if (window.confirm("Mise à jour disponible, voulez-vous recharger la page pour la prendre en compte ?")){
            window.location.reload();
        }
    }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();