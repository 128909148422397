import React, { useState, useEffect } from 'react'

import { Box, IconButton, List, TextField, InputAdornment, Typography, CircularProgress } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search';

import { Link as RouterLink } from 'react-router-dom'

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import ClientListItem from './ListItem';

import InfiniteScroll from 'react-infinite-scroll-component';

import { createClientPath } from '../../../services/routesPaths';
import { fetchClients } from '../../../models/clients';
import { NB_CLIENTS_PER_FETCH } from '../../../config/clients';

export default function ClientsList({db}) {
    const [searchBarValue, setSearchBarValue] = useState("");
    const [matchingClients, setMatchingClients] = useState({
        list: [],
        lastClientName: null,
        hasMore: true
    });

    useEffect(() => {
        fetchClients(db).then(clients => {
            setMatchingClients({
                list: clients,
                lastClientName: (clients.length ? clients[clients.length-1].name : null),
                hasMore: !(clients.length < NB_CLIENTS_PER_FETCH)
            })
        });
    }, [db]);

    const fetchMoreClients = () => {
        fetchClients(db, matchingClients.lastClientName, searchBarValue.trim()).then(clients => {
            setMatchingClients({
                list: [...matchingClients.list, ...clients],
                lastClientName: (clients.length ? clients[clients.length-1].name : null),
                hasMore: !(clients.length < NB_CLIENTS_PER_FETCH)
            })
        });
    };

    let keywords = "";
    
    const onSearchBarValueChange = (event) => {
        document.getElementById("clientsList").scrollTo(0, 0);
        
        keywords = event.target.value;

        setSearchBarValue(keywords);
        
        if(keywords.length === "") {
            fetchClients(db).then(clients => {
                setMatchingClients({
                    list: clients,
                    lastClientName: (clients.length ? clients[clients.length-1].name : null),
                    hasMore: !(clients.length < NB_CLIENTS_PER_FETCH)
                })
            });
        } else {
            fetchClients(db, null, keywords.trim()).then(clients => {
                setMatchingClients({
                    list: clients,
                    lastClientName: (clients.length ? clients[clients.length-1].name : null),
                    hasMore: !(clients.length < NB_CLIENTS_PER_FETCH)
                })
            });
        }
    };

    return <>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'sticky',
            minWidth: '320px',
            padding: '16px',
            '@media (min-width: 600px)': {
                paddingLeft: "24px",
                paddingRight: "24px"
            },
            zIndex: 30,
            boxShadow: (theme) => theme.shadows[3]
        }}>
            <TextField 
                type="search"
                value={searchBarValue}
                placeholder="Rechercher un client/animal"
                onChange={onSearchBarValueChange}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                }}
                sx={{
                    flex: 1,
                    marginRight: "3px"
                }}
            />
            <IconButton
                aria-label="Ajouter un client"
                title="Ajouter un client"
                component={RouterLink}
                to={createClientPath()}
                size="large">
                <PersonAddIcon />
            </IconButton>
        </Box>
        <Box
            id="clientsList"
            sx={{
                overflow: 'scroll'
            }}
        >
            <InfiniteScroll
                next={fetchMoreClients}
                dataLength={matchingClients.list.length}
                hasMore={matchingClients.hasMore}
                scrollableTarget="clientsList"
                scrollThreshold={0.98}
                loader={
                    <Typography sx={{
                        textAlign:"center",
                        marginTop: "10px",
                        marginBottom: "10px"
                    }}>
                        <CircularProgress size="1em"/>
                        &nbsp;&nbsp;
                        Chargement des clients suivants...
                    </Typography>
                }
            >
                <List sx={{
                    width: '100%',
                    padding: 0
                }}>
                    {matchingClients.list.map((client) => <ClientListItem client={client} key={client._id} />)}
                </List>
            </InfiniteScroll>
        </Box>
    </>;
}