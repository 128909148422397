import React from 'react';

import { IconButton, Grid, TextField, InputAdornment, Typography, Divider } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import MapIcon from '@mui/icons-material/Map';

import { CONTACT_MODES } from '../../../config/clients';

export default function ClientInfos({infos}) {
    let address = '';
    let googleMapsUrl = '';
    
    if(address === '') {
        address = (infos.address ? infos.address : '');
        googleMapsUrl = address;
        
        if(infos.postcode) {
            if(address !== "") {
                address += "\n";
                googleMapsUrl += '+';
            }
            address += infos.postcode;
            googleMapsUrl += infos.postcode;

            if(infos.city) {
                address += " "+infos.city;
                googleMapsUrl += '+'+infos.city;
            }
        } else if(infos.city) {
            if(address !== "") {
                address += "\n";
                googleMapsUrl += '+';
            }
            address += infos.city;
            googleMapsUrl += infos.city;
        }
        
        if(googleMapsUrl !== "") {
            googleMapsUrl = "https://www.google.com/maps/search/?api=1&query="+ encodeURIComponent(googleMapsUrl);
        }
    }

    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="lastname"
                    label="Nom"
                    value={infos.lastname}
                    variant="standard"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="firstname"
                    label="Prénom"
                    value={infos.firstname}
                    variant="standard"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="email"
                    label="Adresse mail"
                    value={infos.email}
                    variant="standard"
                    disabled
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <a href={`mailto:${infos.email}`} title="Écrire au client">
                                    <IconButton size="large"><EmailIcon /></IconButton>
                                </a>
                            </InputAdornment>
                        }
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="phone"
                    label="Téléphone"
                    value={infos.phone}
                    variant="standard"
                    disabled
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <a href={`tel:${infos.phone}`}
                                    title="Appeler le client"
                                >
                                    <IconButton size="large">
                                        <PhoneIcon />
                                    </IconButton>
                                </a>
                            </InputAdornment>
                        }
                    }
                />
            </Grid>
            {address && 
                <Grid item xs={12}>
                    <TextField
                        name="address"
                        label="Adresse"
                        value={address}
                        variant="standard"
                        multiline
                        disabled
                        InputProps={googleMapsUrl && {
                            endAdornment:
                                <InputAdornment position="end">
                                    <a
                                        href={googleMapsUrl}
                                        title="Ouvrir l'adresse dans Google Maps"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IconButton size="large">
                                            <MapIcon />
                                        </IconButton>
                                    </a>
                                </InputAdornment>
                            }
                        }
                    />
                </Grid>
            }
            <Grid item xs={12} sm={6}>
                <TextField
                    id="company"
                    label="Entreprise"
                    value={infos.company}
                    variant="standard"
                    disabled
                />
            </Grid>
        </Grid>
        <Divider className="divider" />
        <Typography className="detailsCardTitle" color="primary" gutterBottom>
            Préférences de communication
        </Typography>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="contactMode"
                    label="Mode de contact"
                    value={CONTACT_MODES[infos.contactMode]}
                    variant="standard"
                    disabled
                />
            </Grid>
        </Grid>
        <Divider className="divider-prebuttons" />
        </>
    );
}