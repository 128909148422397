import React, { useContext } from 'react';

import ClientContext from '../../../contexts/ClientContext';

import Screen from '../../UI/Screen';
import SessionForm from '../Form';

import { sessionPath } from '../../../services/routesPaths';
import format from 'date-fns/format';

export default function UpdateSession() {
    const {
        client,
        updateSession,
        getCurrentAnimal,
        getCurrentSession,
        header
    } = useContext(ClientContext);

    const animal = getCurrentAnimal();
    const session = getCurrentSession();
    
    session.date = format(new Date(session.date), 'yyyy-MM-dd');

    return (
        <Screen header={header}>
            <SessionForm
                values={session}
                onSubmit={updateSession}
                cancelButtonProps={{
                    to: sessionPath(client._id,  animal._id, session._id)
                }}
                animal={animal}
            />
        </Screen>
    );
}