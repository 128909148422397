
export function clientsListPath() {
    return '/clients';
}

export function createClientPath() {
    return '/clients/create';
}

export function clientPath(clientId) {
    return '/'+clientId;
}

export function updateClientPath(clientId) {
    return clientPath(clientId)+'/update';
}


export function createAnimalPath(clientId) {
    return clientPath(clientId)+'/animals/create';
}

export function animalPath(clientId, animalId) {
    return clientPath(clientId)+'/'+animalId;
}

export function updateAnimalPath(clientId, animalId) {
    return animalPath(clientId, animalId)+'/update';
}


export function createSessionPath(clientId, animalId) {
    return animalPath(clientId, animalId)+'/sessions/create';
}

export function sessionPath(clientId, animalId, sessionId) {
    return animalPath(clientId, animalId)+'/'+sessionId;
}

export function updateSessionPath(clientId, animalId, sessionId) {
    return sessionPath(clientId, animalId, sessionId)+'/update';
}