import React, { useEffect } from 'react'

import { usePouch } from 'use-pouchdb'

import Screen from '../../UI/Screen'
import ClientsList from '../List'
import { title } from '../../../config/customer';


export default function ClientsHome() {
    const db = usePouch();
    
    useEffect(() => {
        const dbChangesListener = db.changes({
            since: 'now',
            live: true,
            include_docs: true,
        });

        return () => {
            dbChangesListener.cancel();
        };
    }, [db]);
    
    return (
        <Screen header={{title: title}}>
            <ClientsList db={db} />
        </Screen>
    );
}