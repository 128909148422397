import AssignmentIcon from '@mui/icons-material/Assignment';

import { sessions as sessionsConfig } from './customer.js'

export const SESSION_DOC_PREFIX = 'sessions/';

export const SESSION_ICON = <AssignmentIcon />;

export const SESSION_SKELETON_IMAGES_INFOS = {
    horse: {
        'default': {
            width: 1060,
            height: 947,
            ratio: 1.1193241816
        },
        'blackAndWhite': {
            width: 1000,
            height: 887,
            ratio: 1.1273957159
        }
    },
    cat: {
        'default': {
            width: 948,
            height: 574,
            ratio: 1.651567944250871
        },
    },
    dog: {
        'default': {
            width: 963,
            height: 721,
            ratio: 1.3356449376
        },
    },
    cow: {
        'default': {
            width: 988,
            height: 635,
            ratio: 1.555905511811024
        },
    }
};

export const DEFAULT_SHAPE_PROPS = {
    line: {
        stroke: sessionsConfig.drawnEditor.defaultShapeProps.stroke.color,
        strokeWidth: 13
    },
    curvedLine: {
        stroke: sessionsConfig.drawnEditor.defaultShapeProps.stroke.color,
        strokeWidth: 13
    },
    arrow: {
        stroke: sessionsConfig.drawnEditor.defaultShapeProps.stroke.color,
        strokeWidth: 13,
        pointerLength: 20,
        pointerWidth: 20,
    },
    curvedArrow: {
        stroke: sessionsConfig.drawnEditor.defaultShapeProps.stroke.color,
        strokeWidth: 13
    },
    cross: {
        stroke: sessionsConfig.drawnEditor.defaultShapeProps.stroke.color,
        strokeWidth: 13,
        numPoints: 4,
        innerRadius: 30,
        outerRadius: 0
    },
    ellipse: {
        stroke: sessionsConfig.drawnEditor.defaultShapeProps.stroke.color,
        strokeWidth: 13,
        radiusX: 100,
        radiusY: 50,
    },
}

export const ADDED_SHAPE_RULE = {
}