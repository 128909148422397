import React, { useState } from 'react';
import { Arrow as KonvaArrow } from 'react-konva';
import Anchor from './Anchor';

export default function Arrow({
    id,
    shapeProps,
    otherProps,
    stageDimensions,
    isSelected = false,
    onSelect = null,
    onChange = null
}) {
    const shapeRef = React.useRef();
    
    const [arrowPoints, setArrowPoints] = useState(shapeProps.points);
    
    const onAnchorDragMove = (target, x, y) => {
        const newArrowPoints = [...arrowPoints];

        if      (x < 25)                            { x = 25; }
        else if (x > stageDimensions.width - 25)    { x = stageDimensions.width - 25; }

        if      (y < 25)                            { y = 25; }
        else if (y > stageDimensions.height - 25)   { y = stageDimensions.height - 25;  }

        if(target === 1) {
            newArrowPoints[0] = x;
            newArrowPoints[1] = y;
        } else if(target === 2) {
            newArrowPoints[2] = x;
            newArrowPoints[3] = y;
        }

        setArrowPoints(newArrowPoints);
    };

    const onAnchorDragEnd = (target, x, y) => {
        const newArrowPoints = [...arrowPoints];

        if      (x < 25)                            { x = 25; }
        else if (x > stageDimensions.width - 25)    { x = stageDimensions.width - 25; }

        if      (y < 25)                            { y = 25; }
        else if (y > stageDimensions.height - 25)   { y = stageDimensions.height - 25;  }

        if(target === 1) {
            newArrowPoints[0] = Math.round(x);
            newArrowPoints[1] = Math.round(y);
        } else if(target === 2) {
            newArrowPoints[2] = Math.round(x);
            newArrowPoints[3] = Math.round(y);
        }

        onChange(id, {
            ...shapeProps,
            points: newArrowPoints
        });
    };

    const handleSelectShape = () => {
        onSelect(id);
    };
    const onSelectShapeHandler = (onSelect ? handleSelectShape : undefined);
    
    return (
        <>
        <KonvaArrow
            onClick={onSelectShapeHandler}
            onTap={onSelectShapeHandler}
            ref={shapeRef}
            {...shapeProps}
            {...otherProps}
            points={arrowPoints}
        />
        {isSelected && (
            <>
            <Anchor
                number={1}
                x={arrowPoints[0]}
                y={arrowPoints[1]}
                onDragMove={onAnchorDragMove}
                onDragEnd={onAnchorDragEnd}
            />
            <Anchor
                number={2}
                x={arrowPoints[2]}
                y={arrowPoints[3]}
                onDragMove={onAnchorDragMove}
                onDragEnd={onAnchorDragEnd}
            />
            </>
        )}
        </>
    );
}