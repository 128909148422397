import React from 'react'

import { useFormContext, Controller } from "react-hook-form";
import { FormControl, Select as MuiSelect, InputLabel, MenuItem } from '@mui/material';

import ErrorLabel from './ErrorLabel';

export default function Select({ name, label, options, values, errors, required = false, defaultValue = '', ...others } ) {
    const { control } = useFormContext();

    const labelId = name + '-label';

    let hasError = false;
    let errorMessage = "";

    if (errors && errors.hasOwnProperty(name)) {
        hasError = true;
        errorMessage = errors[name].message;
    }

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={(values && values[name]) || defaultValue}
            render={({ field }) =>
                <FormControl
                    required={required}
                    variant="outlined"
                    error={hasError}
                >
                    <InputLabel id={labelId}>{label}</InputLabel>
                    <MuiSelect
                        labelId={labelId}
                        name={name}
                        id={name}
                        label={label}
                        {...others}
                        {...field}
                    >
                        {options.map(option => 
                            <MenuItem
                                value={option.value}
                                key={`${name}-select-${option.value}`}
                                disabled={option.disabled ? true : undefined}
                            >
                                {option.name}
                            </MenuItem>)}
                    </MuiSelect>
                    <ErrorLabel message={errorMessage} />
                </FormControl>
            }
        />
    );
}