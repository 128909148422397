import React, { useState, useContext } from 'react';

import { Card, CardContent, CardActions, Button, Typography, Grid, TextField, Box, Divider, Alert } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom'

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendReportIcon from '@mui/icons-material/AttachEmail';

import ClientContext from '../../../contexts/ClientContext';

import Screen from '../../UI/Screen';
import ConfirmDialog from '../../UI/ConfirmDialog';

import { SESSION_SKELETON_IMAGES_INFOS } from '../../../config/sessions';

import { updateSessionPath } from '../../../services/routesPaths';

import format from 'date-fns/format';

import useImage from 'use-image';
import DrawnReportPreview from '../DrawnReport/Preview/Preview';
import DrawnReportPreviewImage from '../DrawnReport/Preview/PreviewImage';

import { options as config } from '../../../config/customer.js';

export default function ViewSession({template = 'default'}) {
    const {
        client,
        getCurrentAnimal,
        getCurrentSession,
        deleteSession,
        sendReport,
        header
    } = useContext(ClientContext);
    
    const animal = getCurrentAnimal();

    const session = getCurrentSession();

    const date = format(new Date(session.date+'T00:00:00.000Z'), 'dd/MM/yyyy');

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);


    let drawnReportAvailable = false;

    let imageInfos = null;
    let imageLeftSrc = null;
    let imageRightSrc = null;

    if(SESSION_SKELETON_IMAGES_INFOS.hasOwnProperty(animal.type)) {
        drawnReportAvailable = true;
        
        imageInfos = SESSION_SKELETON_IMAGES_INFOS[animal.type][template];
        
        imageLeftSrc = '/assets/skeletonImgs/'+animal.type+'/'+template+'/left.jpg';
        imageRightSrc = '/assets/skeletonImgs/'+animal.type+'/'+template+'/right.jpg';
    }

    const [imageLeft] = useImage(imageLeftSrc);
    const [imageRight] = useImage(imageRightSrc);
    

    const handleSendReport = () => {
        sendReport(session);
    };

    const handleDeleteSession = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmDeleteSession = () => {
        deleteSession(session);
    };

    const handleCancelDeleteSession = () => {
        setConfirmDialogOpen(false);
    };

    const renderReportStatusHeader = () => {
        if(session.generateReport) {
            if(!config.sendReports) {
                return  <Alert severity="info">
                            Le compte rendu est en cours de génération.
                        </Alert>;
            } else if(!session.sendReport) {
                return  <Alert severity="info" action={
                    <Button 
                        color="inherit"
                        size="small"
                        startIcon={<SendReportIcon />}
                        onClick={handleSendReport}
                    >
                        Envoyer une fois généré
                    </Button>
                }>
                    Le compte rendu est en cours de génération.
                </Alert>;
            } else {
                return  <Alert severity="info">
                    Le compte rendu est en cours de génération, il sera envoyé dans la foulée.
                </Alert>;
            }
        } else {
            if(!config.sendReports) {
                return  <Alert severity="success">
                            Le compte rendu a bien été généré.
                        </Alert>;
            } else {
                if(session.sendReport) {
                    return  <Alert severity="info">
                                Le compte rendu a bien été généré, il est en cours d'envoi.
                            </Alert>;
                } else if(session.reportSent) {
                    return  <Alert severity="success" action={
                                <Button 
                                    color="inherit"
                                    size="small"
                                    startIcon={<SendReportIcon />}
                                    onClick={handleSendReport}
                                >
                                    Renvoyer
                                </Button>
                            }>
                                Le compte rendu a bien été généré et envoyé (le {format(new Date(session.reportSent), 'dd/MM/yyyy')}).
                            </Alert>;
                } else {
                    return  <Alert severity="warning" action={
                                <Button 
                                    color="inherit"
                                    size="small"
                                    startIcon={<SendReportIcon />}
                                    onClick={handleSendReport}
                                >
                                    Envoyer
                                </Button>
                            }>
                                Le compte rendu a bien été généré mais n'a pas encore été envoyé.
                            </Alert>;
                }
            }
        }
    };
    
    return (
        <Screen header={header}>
            <Card elevation={3}>
                <CardContent className="detailsCardContent">
                    {renderReportStatusHeader()}
                    <Typography
                        className="detailsCardTitle"
                        color="primary"
                        gutterBottom
                        sx={{mt: '15px'}}
                    >
                        Compte rendu de la séance
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                id="date"
                                label="Date"
                                value={date}
                                variant="standard"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="number"
                                label="Numero"
                                value={session.number}
                                variant="standard"
                                disabled
                            />
                        </Grid>
                        {session.reason &&
                        <Grid item xs={12}>
                            <TextField
                                name="reason"
                                label="Motif de la séance"
                                value={session.reason}
                                variant="standard"
                                multiline
                                disabled
                            />
                        </Grid>
                        }
                        {session.treatment &&
                        <Grid item xs={12}>
                            <TextField
                                name="treatment"
                                label="Résumé du traitement"
                                value={session.treatment}
                                variant="standard"
                                multiline
                                disabled
                            />
                        </Grid>
                        }
                    </Grid>
                    {drawnReportAvailable && 
                        <>
                        <Box my={2}>
                            <Divider />
                        </Box>
                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={6}>
                                {session?.shapes?.left
                                ?
                                    <DrawnReportPreview
                                        direction="left"
                                        image={imageRight}
                                        imageInfos={imageInfos}
                                        shapes={session.shapes.right}
                                    />
                                :
                                    <DrawnReportPreviewImage
                                        image={imageRightSrc}
                                        direction="left"
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {session?.shapes?.right
                                ?
                                    <DrawnReportPreview
                                        direction="right"
                                        image={imageLeft}
                                        imageInfos={imageInfos}
                                        shapes={session.shapes.left}
                                    />
                                :
                                    <DrawnReportPreviewImage
                                        image={imageLeftSrc}
                                        direction="right"
                                    />
                                }
                            </Grid>
                        </Grid>
                        </>
                    }
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteSession}
                        color="secondary"
                    >
                        Supprimer
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        component={RouterLink}
                        to={updateSessionPath(client._id, animal._id, session._id)}
                    >
                        Modifier
                    </Button>
                </CardActions>
            </Card>

            <ConfirmDialog
                open={confirmDialogOpen}
                title="Suppression d'un compte-rendu de séance"
                onCancel={handleCancelDeleteSession}
                onConfirm={handleConfirmDeleteSession}
            >
                Vous êtes sur le point de supprimer définitivement ce compte-rendu de séance. Souhaitez-vous continuer ?
            </ConfirmDialog>
        </Screen>
    );
}