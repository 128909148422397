import React, { useState } from 'react';

import { styled } from '@mui/system';

import { Card, CardContent, CardActions, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TextField } from '../UI/Forms';

import LoginIcon from '@mui/icons-material/Login';

import * as yup from 'yup';

import { REQUIRED_FIELD_ERROR_MESSAGE} from "../../config/forms"

const validationSchema = yup.object().shape({
    username: yup.string()
        .required(REQUIRED_FIELD_ERROR_MESSAGE),
    password: yup.string()
        .required(REQUIRED_FIELD_ERROR_MESSAGE)
});

export default function LoginForm({handleLogin}) {
    const methods = useForm({
        mode: "onBlur",
        resolver: yupResolver(validationSchema)
    });

    const { handleSubmit, formState: { errors } } = methods;

    const onSubmit = (data) => {
        setState({
            loading: true,
            error: ''
        });

        handleLogin(data).catch((errorMessage) => {
            setState({
                loading: false,
                error: errorMessage
            });
        });
    };

    const [state, setState] = useState({
        loading: false
    });

    const LoginFormField = styled(TextField)(({ theme }) => ({
        marginTop: "20px"
    }));

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Card elevation={3} sx={{
                    maxWidth: "550px",
                    margin: "30px auto !important",
                    padding: "20px"
                }}>
                    <CardContent>
                        {state.error &&
                            <Typography color="error">
                                {state.error}
                            </Typography>
                        }
                        <LoginFormField 
                            name="username"
                            label="Nom d'utilisateur"
                            errors={errors}
                            required
                        />
                        <LoginFormField
                            name="password"
                            type="password"
                            label="Mot de passe"
                            errors={errors}
                            required
                        />
                    </CardContent>
                    <CardActions>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            startIcon={<LoginIcon />}
                            className="loginFormButton"
                            loading={state.loading}
                            loadingPosition="start"
                            sx={{
                                margin: "10px auto 8px"
                            }}
                        >
                            Connexion
                        </LoadingButton>
                    </CardActions>
                </Card>
            </form>
        </FormProvider>
    );
}