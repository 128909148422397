import React from 'react'

import { useFormContext, Controller } from "react-hook-form";
import { TextField as MuiTextField } from '@mui/material';

import ErrorLabel from './ErrorLabel';

export default function TextField(props) {
    const { control } = useFormContext();
    const { name, label, type = "text", values, errors, onChangeCustom, ...others } = props;

    let hasError = false;
    let errorMessage = "";

    if (errors && errors.hasOwnProperty(name)) {
        hasError = true;
        errorMessage = errors[name].message;
    }
    
    return (
        <>
        <Controller
            name={name}
            control={control}
            defaultValue={(values && values[name]) || ''}
            render={({ field: { onChange, onBlur, value, ref } }) => 
                <MuiTextField 
                    name={name}
                    id={name}
                    label={label}
                    type={type}
                    error={hasError}
                    variant="outlined"
                    onChange={(event) => {
                        onChange(event);
                        if(onChangeCustom) { onChangeCustom(event.target.value); }
                    }}
                    onBlur={onBlur}
                    value={value ?? undefined}
                    ref={ref}
                    {...others}
                />
            }
        />
        <ErrorLabel message={errorMessage} />
        </>
    );
}
/*
export default function TextField(props) {
    const { control } = useFormContext();
    const { name, label, values, errors, ...others } = props;

    let hasError = false;
    let errorMessage = "";

    if (errors && errors.hasOwnProperty(name)) {
        hasError = true;
        errorMessage = errors[name].message;
    }
    
    return (
        <>
        <Controller
            name={name}
            control={control}
            defaultValue={(values && values[name]) || ''}
            render={({ field }) =>
                <MuiTextField 
                    name={name}
                    id={name}
                    label={label}
                    error={hasError}
                    variant="outlined"
                    {...others}
                    {...field}
                />
            }
        />
        <ErrorLabel message={errorMessage} />
        </>
    );
}
*/