import React, { useContext } from 'react';

import ClientContext from '../../../contexts/ClientContext';

import Screen from '../../UI/Screen';
import AnimalForm from '../Form';

import { clientPath } from '../../../services/routesPaths';

export default function CreateAnimal() {
    const { client, createAnimal, header } = useContext(ClientContext);

    return (
        <Screen header={header}>
            <AnimalForm
                animal={{}}
                onSubmit={createAnimal}
                cancelButtonProps={{
                    to: clientPath(client._id)
                }}
            />
        </Screen>
    );
}