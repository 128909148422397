import { CLIENTS_LIST_ICON, CLIENT_ICON } from "../config/clients";
import { ANIMAL_ICON } from "../config/animals";
import { SESSION_ICON } from "../config/sessions";


export function withClientsList() {
    return {
        links: [{
            icon: CLIENTS_LIST_ICON,
            label: "Clients",
            route: "/clients"
        }]
    };
}

export function addClientLink(breadcrumbs, clientOrName) {
    const lastChild = ((typeof clientOrName === "string") ? true : false);

    breadcrumbs.links.push({
        icon: CLIENT_ICON,
        label: (lastChild ? clientOrName : clientOrName.name),
        route: (!lastChild ? "/"+clientOrName._id : null)
    });

    return breadcrumbs;
}

export function addCreateClientLink(breadcrumbs) {
    breadcrumbs.links.push({
        icon: CLIENT_ICON,
        label: "Nouveau client",
        route: null
    });

    return breadcrumbs;
}

export function addAnimalLink(breadcrumbs, clientId, animalOrName) {
    const lastChild = ((typeof animalOrName === "string") ? true : false);

    breadcrumbs.links.push({
        icon: ANIMAL_ICON,
        label: (lastChild ? animalOrName : animalOrName.name),
        route: (!lastChild ? "/"+clientId+"/"+animalOrName._id : null)
    });

    return breadcrumbs;
}

export function addSessionLink(breadcrumbs, sessionName) {
    breadcrumbs.links.push({
        icon: SESSION_ICON,
        label: sessionName,
        route: null
    });

    return breadcrumbs;
}