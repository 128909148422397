import React from 'react';

import { Container, Box, Breadcrumbs as MuiBreadcrumbs } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// maxItems={4}

export default function Breadcrumbs({links}) {
    return (
        <Box
            sx={{
                display: "flex",
                color: (theme) => theme.palette.secondary.contrastText,
                minHeight: "30px",
                padding: "5px 15px",
                backgroundColor: (theme) => theme.palette.secondary.main,
                boxShadow: (theme) => theme.shadows[1]
            }}
        >
            <Container sx={{
                paddingLeft: "0 !important",
                paddingRight: "0 !important",
                "& nav": {
                    color: (theme) => theme.palette.secondary.contrastText + ' !important',
                    lineHeight: 2
                }
            }}>
                <MuiBreadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{color: (theme) => theme.palette.secondary.contrastText}} />}
                    className='breadcrumbs'
                >
                    <RouterLink
                        key="home"
                        to={{ pathname: "/" }}
                        className="breadcrumb-link"
                    >
                        <HomeIcon/>
                    </RouterLink>
                    { links.map((link) => 
                        (link.route ? 
                            <RouterLink
                                key={link.label}
                                to={{
                                    pathname: link.route,
                                    state: link.state
                                }}
                                className="breadcrumb-link"
                            >
                                {link.icon && link.icon} {link.label}
                            </RouterLink>
                        :
                            <span
                                key={link.label}
                                className="breadcrumb-link breadcrumb-last-link"
                            >
                                {link.icon && link.icon} {link.label}
                            </span>
                        )
                    )}
                </MuiBreadcrumbs>
            </Container>
        </Box>
    );
}