import React, { useState } from 'react';
import { Line, Shape } from 'react-konva';
import Anchor from './Anchor';

export default function CurvedArrow({
    id,
    shapeProps,
    otherProps,
    stageDimensions,
    isSelected = false,
    onSelect = null,
    onChange = null
}) {
    const shapeRef = React.useRef();
    
    const [arrowPoints, setArrowPoints] = useState(shapeProps.points);

    const onAnchorDragMove = (target, x, y) => {
        const newArrowPoints = [...arrowPoints];

        if      (x < 25)                            { x = 25; }
        else if (x > stageDimensions.width - 25)    { x = stageDimensions.width - 25; }

        if      (y < 25)                            { y = 25; }
        else if (y > stageDimensions.height - 25)   { y = stageDimensions.height - 25;  }

        if(target === 1) {
            newArrowPoints[0] = x;
            newArrowPoints[1] = y;
        } else if(target === 2) {
            newArrowPoints[2] = x;
            newArrowPoints[3] = y;
        } else {
            newArrowPoints[4] = x;
            newArrowPoints[5] = y;
        }

        setArrowPoints(newArrowPoints);
    };

    const onAnchorDragEnd = (target, x, y) => {
        const newArrowPoints = [...arrowPoints];

        if      (x < 25)                            { x = 25; }
        else if (x > stageDimensions.width - 25)    { x = stageDimensions.width - 25; }

        if      (y < 25)                            { y = 25; }
        else if (y > stageDimensions.height - 25)   { y = stageDimensions.height - 25;  }

        if(target === 1) {
            newArrowPoints[0] = Math.round(x);
            newArrowPoints[1] = Math.round(y);
        } else if(target === 2) {
            newArrowPoints[2] = Math.round(x);
            newArrowPoints[3] = Math.round(y);
        } else {
            newArrowPoints[4] = Math.round(x);
            newArrowPoints[5] = Math.round(y);
        }

        onChange(id, {
            ...shapeProps,
            points: newArrowPoints
        });
    };

    const handleSelectShape = () => {
        onSelect(id);
    };
    const onSelectShapeHandler = (onSelect ? handleSelectShape : undefined);

    return (
        <>
        <Shape
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(arrowPoints[0], arrowPoints[1]);
                context.quadraticCurveTo(
                    arrowPoints[2],
                    arrowPoints[3],
                    arrowPoints[4],
                    arrowPoints[5]
                );
                
                //  Drawing pointer :
                const PI2 = Math.PI * 2;
                const radians = (Math.atan2((arrowPoints[5] - arrowPoints[3]), (arrowPoints[4] - arrowPoints[2])) + PI2) % PI2;
                
                //  To close shape properly on the line:
                const pointerLength = shapeProps.strokeWidth*2;
                const widthFix = Math.floor(shapeProps.strokeWidth/3);
                
                context.translate(arrowPoints[4], arrowPoints[5]);
                context.rotate(radians);
                context.moveTo(-pointerLength, -pointerLength);
                context.lineTo(widthFix, widthFix+1);
                context.moveTo(0, -0.5);
                context.lineTo(-pointerLength, pointerLength);
                
                context.fillStrokeShape(shape);
            }}
            onClick={onSelectShapeHandler}
            onTap={onSelectShapeHandler}
            ref={shapeRef}
            {...shapeProps}
            {...otherProps}
        />
        {isSelected && (
            <>
            <Line
                dash={[10, 10, 0, 10]}
                strokeWidth={3}
                stroke="black"
                lineCap="round"
                opacity={0.3}
                points={arrowPoints}
            />
            <Anchor
                number={1}
                x={arrowPoints[0]}
                y={arrowPoints[1]}
                onDragMove={onAnchorDragMove}
                onDragEnd={onAnchorDragEnd}
            />
            <Anchor
                number={2}
                x={arrowPoints[2]}
                y={arrowPoints[3]}
                onDragMove={onAnchorDragMove}
                onDragEnd={onAnchorDragEnd}
            />
            <Anchor
                number={3}
                x={arrowPoints[4]}
                y={arrowPoints[5]}
                onDragMove={onAnchorDragMove}
                onDragEnd={onAnchorDragEnd}
            />
            </>
        )}
        </>
    );
}