import React, { useContext } from 'react';

import ClientContext from '../../../contexts/ClientContext';

import Screen from '../../UI/Screen';
import ClientForm from '../Form';

import { animalPath } from '../../../services/routesPaths';

export default function UpdateAnimal() {
    const {
        client,
        getCurrentAnimal,
        updateAnimal,
        header
    } = useContext(ClientContext);

    const animal = getCurrentAnimal();

    return (
        <Screen header={header}>
            <ClientForm 
                animal={animal}
                onSubmit={updateAnimal}
                cancelButtonProps={{
                    to: animalPath(client._id, animal._id)
                }}
            />
        </Screen>
    );
}