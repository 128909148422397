import React, { useState} from 'react';

import { options, animals as animalsConfig } from '../../../config/customer.js';

import { Grid, Card, CardContent, CardActions, Typography, Button, TextField as MuiTextField, FormGroup, FormControlLabel, Checkbox, InputAdornment, Divider } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom'

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TombstoneIcon from '../../UI/icons/TombstoneIcon';

import { TextField, Select } from '../../UI/Forms';

import * as yup from 'yup';

import { ANIMALS_SEXES, ANIMALS_TYPES, CHECKUP_REMINDER, STERILIZED_OPTIONS } from "../../../config/animals"

import { REQUIRED_FIELD_ERROR_MESSAGE} from "../../../config/forms"
import { getAnimalAge, getAnimalDeathAge } from '../../../models/animals';

const currentYear = new Date().getFullYear();

const typeOptions = [], typeOptionsValues = [''];

for (const [type, animal] of Object.entries(ANIMALS_TYPES)) {
    typeOptions.push({ value: type, name: animal.icon + ' ' + animal.name });
    typeOptionsValues.push(type);
}

const sexOptions = [], sexOptionsValues = [''];

for (const value in ANIMALS_SEXES) {
    sexOptions.push({ value: value, name: ANIMALS_SEXES[value] });
    sexOptionsValues.push(value);
}


let sterilizedOptions;
let checkupReminderOptions;

const validationSchemaDef = {
    name: yup.string()
        .required(REQUIRED_FIELD_ERROR_MESSAGE),
    sex: yup.string()
        .required(REQUIRED_FIELD_ERROR_MESSAGE)
        .oneOf(sexOptionsValues, 'Sexe invalide.'),
    type: yup.string()
        .required(REQUIRED_FIELD_ERROR_MESSAGE)
        .oneOf(typeOptionsValues, 'Type d\'animal invalide.'),
    race: yup.string(),
    deceased: yup.boolean(),
    birthYear: yup.number()
        .integer()
        .nullable(true)
        .transform((current, original) => (original === '') ? null : current)
        .typeError('Doit être un nombre.')
        .min(currentYear-80, 'Année trop ancienne.')
        .max(currentYear, 'Année pas encore atteinte.'),
        //.lessThan(yup.ref('deathYear'), 'Année de naissance après le décès...'),
    deathYear: yup.number()
        .integer()
        .nullable(true)
        .transform((current, original) => (original === '') ? null : current)
        .typeError('Doit être un nombre.')
        .min(yup.ref('birthYear'), 'Année trop ancienne.')
        .max(currentYear, 'Année pas encore atteinte.'),
        //.moreThan(yup.ref('birthYear')),
    notes: yup.string()
};

if(animalsConfig.privateNotes) {
    validationSchemaDef.privateNotes = yup.string();
}

if(animalsConfig.veterinary) {
    validationSchemaDef.veterinary = yup.string();
}

if(animalsConfig.sterilized) {
    sterilizedOptions = [];
    const sterilizedOptionsValues = [];

    for (const option in STERILIZED_OPTIONS) {
        sterilizedOptions.push({ value: option, name: STERILIZED_OPTIONS[option] });
        sterilizedOptionsValues.push(option);
    }

    validationSchemaDef.sterilized = yup.string()
        .required(REQUIRED_FIELD_ERROR_MESSAGE)
        .oneOf(sterilizedOptionsValues, 'Type de sterilisation/castration obligatoire.');
}

if(options.checkupReminders) {
    checkupReminderOptions = [];
    const checkupReminderOptionsValues = [""];

    for (const interval of options.checkupReminders.intervals) {
        checkupReminderOptions.push({ value: interval, name: CHECKUP_REMINDER[interval] });
        checkupReminderOptionsValues.push(interval);
    }

    validationSchemaDef.checkupReminder = yup.string()
        .required(REQUIRED_FIELD_ERROR_MESSAGE)
        .oneOf(checkupReminderOptionsValues, 'Rappel de check-up invalide.');
}

const validationSchema = yup.object().shape(validationSchemaDef);

export default function Form({animal, onSubmit, cancelButtonProps}) {
    const methods = useForm({
        mode: "onBlur",
        resolver: yupResolver(validationSchema)
    });

    const { register, handleSubmit, formState: { errors }, setValue } = methods;

    const [birthYear, setBirthYear] = useState(animal?.birthYear ?? '');

    const [age, setAge] = useState(getAnimalAge(animal.birthYear));

    const [deathStatus, setDeathStatus] = useState({
        deceased: animal?.deceased ?? false,
        deathYear: animal?.deathYear ?? '',
        deathAge: getAnimalDeathAge(animal.birthYear, animal.deathYear)
    });

    const handlebirthYearChange = (event) => {
        let birthYearStr = event.target.value;
        let birthYearInt = null;
        let age = null;
        
        if(birthYearStr !== '') {
            if(!birthYearStr.match(/^\d{0,4}$/)) {
                setBirthYear(birthYear);
                return;
            }

            birthYearInt = parseInt(birthYearStr);

            if(birthYearInt > currentYear) {
                birthYearStr = currentYear.toString();
                birthYearInt = currentYear;
            }

            age = currentYear - birthYearInt;
            
            if(deathStatus.deceased && deathStatus.deathYear) {
                const deathAge = deathStatus.deathYear-birthYearInt;
                
                setDeathStatus({
                    ...deathStatus,
                    deathAge: deathAge
                });
            }
        } else if(deathStatus.deceased && deathStatus.deathYear) {
            setDeathStatus({
                ...deathStatus,
                deathAge: null
            });
        }

        setBirthYear(birthYearStr);
        setAge(age);

        setValue('birthYear', birthYearStr);
    };

    const handleDeceasedChange = (event) => {
        if(event.target.checked) {
            const deathAge = (age != null ? age : null);
            
            setDeathStatus({
                deceased: true,
                deathYear: currentYear,
                deathAge: deathAge
            });
            setAge(null);
            
            setValue('deceased', true);
            setValue('deathYear', currentYear);
        } else {
            setDeathStatus({
                deceased: false,
                deathYear: '',
                deathAge: null
            });

            if(birthYear) {
                const age = currentYear-parseInt(birthYear);

                setAge(age);
            }
    
            setValue('deceased', false);
            setValue('deathYear', null);
        }
    };

    const handleDeathYearChange = (event) => {
        let deathYearStr = event.target.value;
        let deathYearInt = null;

        if(deathYearStr === '') {
            deathYearInt = currentYear;
        } else {
            if(!deathYearStr.match(/^\d{0,4}$/)) {
                setDeathStatus(deathStatus);
                return;
            }
            deathYearInt = parseInt(deathYearStr);
        }

        if(deathYearInt > currentYear) {
            deathYearStr = currentYear.toString();
            deathYearInt = currentYear;
        }

        if(birthYear) {
            let deathAge = deathYearInt-parseInt(birthYear);
            
            if(deathAge < 0) { deathAge = 0; }

            setDeathStatus({
                deceased: true,
                deathYear: deathYearStr,
                deathAge: deathAge
            });
        } else {
            setDeathStatus({
                ...deathStatus,
                deathYear: deathYearStr
            });
        }

        setValue('deathYear', deathYearStr);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Card elevation={3}>
                    <CardContent>
                        <Typography className="detailsCardTitle" color="primary" gutterBottom>
                            Informations
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="name"
                                    label="Nom"
                                    values={animal}
                                    errors={errors}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    name="sex"
                                    label="Sexe"
                                    options={sexOptions}
                                    values={animal}
                                    defaultValue={"undefined"}
                                    errors={errors}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    name="type"
                                    label="Type"
                                    options={typeOptions}
                                    values={animal}
                                    errors={errors}
                                    required
                                    disabled={animal?.sessions?.length ? "disabled" : undefined}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="race"
                                    label="Race"
                                    values={animal}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MuiTextField 
                                    label="Année de naissance"
                                    variant="outlined"
                                    onChange={handlebirthYearChange}
                                    value={birthYear}
                                    InputProps={(age !== null && !deathStatus.deceased) ? {
                                        endAdornment:   <InputAdornment position="end">
                                                            <span>
                                                            ({age} an{age > 1 && 's'})
                                                            </span>
                                                        </InputAdornment>,
                                    } : undefined}
                                />
                                <input
                                    type="hidden"
                                    {...register("birthYear")}
                                    value={birthYear}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormGroup sx={{
                                    height: '55px',
                                    '& label': {
                                        margin: 'auto',
                                        '& span.MuiFormControlLabel-label': {
                                            lineHeight: '1rem',
                                        },
                                        '& span.checkbox': {
                                            '& svg': {
                                                height: "1rem"
                                            }
                                        }
                                    }
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                onChange={handleDeceasedChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                defaultChecked={animal?.deceased}
                                            />
                                        }
                                        label={
                                            <span className="checkbox">
                                                <TombstoneIcon />
                                                Décédé
                                            </span>
                                        } />
                                </FormGroup>
                                <input
                                    type="hidden"
                                    {...register("deceased")}
                                    value={deathStatus.deceased}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MuiTextField 
                                    label="Année de décès"
                                    variant="outlined"
                                    onChange={handleDeathYearChange}
                                    value={deathStatus.deathYear}
                                    InputProps={deathStatus.deathAge !== null ? {
                                        endAdornment:   <InputAdornment position="end">
                                                            <span>
                                                                (à {deathStatus.deathAge} an{deathStatus.deathAge > 1 && 's'})
                                                            </span>
                                                        </InputAdornment>,
                                    } : undefined}
                                    disabled={deathStatus.deceased ? undefined : true}
                                />
                                <input
                                    type="hidden"
                                    {...register("deathYear")}
                                    value={deathStatus.deathYear}
                                />
                            </Grid>
                            {animalsConfig.sterilized &&
                                <Grid item xs={12} sm={6}>
                                    <Select
                                        name="sterilized"
                                        label="Stérilisée/Castré"
                                        options={sterilizedOptions}
                                        values={animal}
                                        defaultValue={"undefined"}
                                        errors={errors}
                                        required
                                    />
                                </Grid>
                            }
                            {animalsConfig.veterinary &&
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="veterinary"
                                        label="Vétérinaire usuel"
                                        values={animal}
                                        errors={errors}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <TextField
                                    name="notes"
                                    label="Notes"
                                    values={animal}
                                    errors={errors}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            {animalsConfig.privateNotes &&
                                <Grid item xs={12}>
                                    <TextField
                                        name="privateNotes"
                                        label="Notes privées"
                                        values={animal}
                                        errors={errors}
                                        multiline
                                        rows={2}
                                    />
                                </Grid>
                            }
                        </Grid>
                        {options.checkupReminders &&
                        <>
                            <Divider className="divider" />
                            <Typography className="detailsCardTitle" color="primary" gutterBottom>
                                Préférences
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Select
                                        name="checkupReminder"
                                        label="Rappel check-up"
                                        options={checkupReminderOptions}
                                        values={animal}
                                        defaultValue={options.checkupReminders.default}
                                        errors={errors}
                                    />
                                </Grid>
                            </Grid>
                            <Divider className="divider" />
                        </>
                        }
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="contained"
                            color="cancel"
                            startIcon={<CancelIcon />}
                            component={RouterLink}
                            {...cancelButtonProps}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            startIcon={<SaveIcon />}
                            onClick={handleSubmit}
                        >
                            Enregistrer
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </FormProvider>
    );
}