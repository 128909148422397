import React from 'react';

import { useNavigate } from 'react-router-dom'

import { usePouch } from 'use-pouchdb'

import { createClient } from '../../../models/clients';

import Screen from '../../UI/Screen';
import ClientForm from '../Form';

import { addCreateClientLink, withClientsList } from '../../../services/breadcrumbs';

import { CLIENT_ICON } from '../../../config/clients';
import { clientPath } from '../../../services/routesPaths';

export default function CreateClient() {
    const navigate = useNavigate();
    const db = usePouch();
    
    const breadcrumbs = withClientsList();
    addCreateClientLink(breadcrumbs);

    const header = {
        titleIcon: CLIENT_ICON,
        title: "Créer un client",
        breadcrumbs: breadcrumbs
    }

    const onSubmit = (data) => {
        createClient(db, data).then(clientId => {
            navigate(clientPath(clientId));
        });
    };
    
    return (
        <Screen header={header}>
            <ClientForm
                onSubmit={onSubmit}
                cancelButtonProps={{
                    to: '/clients'
                }}
            />
        </Screen>
    );
}